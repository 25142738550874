* {
  box-sizing: border-box;
}
html {
  height: 100%;
}
body {
  height: 100%;
  margin: 0px;
}
.App {
  height: 100%;
}
.wrapper {
  height: 100%;
  display: grid;
  padding: 20px;
}
.inner {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  background-color: #343a40 !important;
  /* padding: 10px; */
}
video {
  width: 100%;
}
.controlsHoverActive:hover > .controlsHover {
  display: block !important;
}
@media only screen and (max-width: 768px) {
  .wrapper {
    display: grid;
    grid-template-columns: 1fr !important;
  }
}
