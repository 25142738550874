.nav-link {
  font-size: 13px !important;
  font-weight: 600 !important;
  padding: 0.05rem !important;
  margin-top: 10px;
  color: #1a4774 !important;
}
.nav-link:hover {
  color: #e92125 !important;
}
.dropdown-menu {
  border: none;
  min-width: 250px;
  max-width: 300px;
}
.dropdown-item {
  color: #1a4774;
  font-size: 13px;
  /* text-transform: capitalize; */
  padding: 12px 3px;
  padding-left: 18px !important;
  padding-right: 18px !important;
  font-family: "Roboto", sans-serif;
  display: block !important;
  font-weight: 500;
  text-overflow: clip;
}

.sticky-top {
  top: -10px;
}
.profile-nav-dropdown {
  min-width: 130px !important;
  max-width: 200px !important;
  left: -20px;

}

@media (min-width: 1200px) {
  .dropdown:hover > .dropdown-menu {
    display: block;
  }
  
  .dropdown-item:hover {
    background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
    color: #fff;
  }
}

.dropdown-item-user {
  color: #001b4b;
  font-size: 14px;
  text-align: center;
  text-transform: capitalize;
  padding: 5px 10px;
  font-family: "Roboto", sans-serif;
  display: inline-block !important;
  font-weight: 500;
  text-overflow: clip;
  text-decoration: none;
  width: 100%;
}