.input-login-modal {
  width: 100%;
  height: 42px;
  overflow: hidden;
  float: left;
  border: 1px lightgray solid;
  border-radius: 2px !important;
  padding-left: 12px;
  margin-bottom: 20px;
  color: #a9a9a9;
}
.label-name-login {
  color: #444;
  font-weight: 400;
  font-size: 15px;
}
.modal-title {
  font-family: "Roboto Condensed", sans-serif;
  line-height: 1.55em;
  color: #444;
  font-weight: 600;
  font-size: 20px;
}
.signIn-link{
  color: #1a4774;
}
