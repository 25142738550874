.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.header {
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
}
.header-info {
  color: #fff;
  margin-left: 20px;
  font-size: 14px;
  font-weight: 400;
}
.header-info:hover{
  color: #fff;
  margin-left: 20px;
  font-size: 14px;
  font-weight: 400;
}
.header-search {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-left: 20px;
}

.header-search [class*="icon-"] {
  position: absolute;
  top: 1px;
  bottom: 1px;
  right: 1px;
  z-index: 1;
  cursor: pointer;
  display: block;
  color: #444;
  font-size: 20px;
  line-height: 35px;
  min-width: 40px;
  text-align: center;
  background-color: transparent;
}

.header-search input[type="text"],
.header-search input[type="search"] {
  position: absolute;
  right: 40;
  top: 1px;
  border: 2px solid #fff;
  /* width: 40px; */
  width: 220px;
  height: 38px;
  padding: 15px;
  font-size: 14px;
  line-height: 1.2em;
  color: #fff;
  transition: 0.2s;
  background: transparent;
  border-radius: 50px;
}

.header-search:hover input[type="text"],
.header-search:hover input[type="search"],
.header-search input[type="text"]:focus,
.header-search input[type="search"]:focus {
  width: 220px;
  padding: 0 45px 0 10px;
  border-color: #ccc;
}

.header-search input[type="submit"],
.header-search button[type="submit"] {
  display: none;
}

.header-search ::-webkit-input-placeholder {
  color: #fff;
}

.header-search ::-moz-placeholder {
  color: #fff;
}

.header-search :-ms-input-placeholder {
  color: #fff;
}

.header-search :-moz-placeholder {
  color: #fff;
}
.country-drop {
  margin-left: 190px;
}
.country-drop .form-control {
  border-radius: 0;
  padding: 10px 7px 10px 7px;
}
.dropbtn {
  background-color: transparent;
  color: #fff;
  padding: 16px;
  /* margin-left: 170px; */
  font-size: 14px;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 9999;
}

.dropdown-content span {
  color: #001b4b;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content span:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: transparent;
}

.dropbtn img {
  width: 30px;
  height: 20px;
  object-fit: cover;
  margin-right: 10px;
  position: relative;
  top: -1px;
}

.dropdown-content span img {
  width: 30px;
  height: 20px;
  object-fit: cover;
  margin-right: 10px;
}
.header-icon-item {
  background-color: #fff;
  border-radius: 50%;
  height: 22px;
  width: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.header-icon-item i {
  text-align: center;
  margin-right: 0px !important;
  color: linear-gradient(90deg, #476b8f 100%, #1a4774 0%);
  font-size: 10px;
}
.header-icon-item:hover{
  opacity: 0.5;
}
.search-icon{
  position: absolute;
  right: 0px;
  left: 191px;
  top: 12px;
  color: #fff;
}




.searchInputs {
  z-index: 1;
}

input:focus {
  outline: none;
}

.dataResult {
  margin-top: 5px;
  cursor: pointer;
  width: 200px;
  height: 240px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  overflow-y: auto;
  z-index: 9999;
  position: absolute !important;
  top: 35px;
  right: -150px;
  border-radius: 10px;
}

.dataResult .dataItem {
  width: 100%;
  height: 50px;
  color: black;
}

.dataItem p {
  margin-left: 10px;
}
a {
  text-decoration: none;
}

.dataItem:hover {
  color: #0066b3;
}

#clearBtn {
  cursor: pointer;
}

