.icn-text-alt {
  /* display: flex; */
  text-decoration: none;
  max-width: 100%;
  margin-bottom: 6px;
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
  padding: 20px;
  color: #fff;
}
.heading {
  margin-bottom: 0;
  font-size: 20px;
  line-height: 34px;
  color: #fff;
  transition: 0.25s;
}
.card-discription {
  font-size: 16px;
  line-height: 25px;
  font-family: 'Work Sans', sans-serif;
}
.card-size-home{
  height: 320px;
}