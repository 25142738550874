/* #linone {
  list-style: none !important;
}
#linone > li {
  list-style: none !important;
} */
ul {
  list-style: none !important;
}
li {
  list-style: none !important;
}

ul {
  list-style: none !important;
}

a:link {
  text-decoration: none;
}

.footer-nav-links {
  color: #787878;
  font-weight: bold;
}

.footer-nav-links:hover {
  color: #1a4774;
}

.footer-top {
  background-color: #f2f2f2 !important;
}

h3 {
  font-family: "Roboto Condensed", sans-serif;
  line-height: 1em;
  margin-bottom: 20px;
  font-weight: bold;
}

h3+.h-decor {
  position: relative;
  top: -20px;
  height: 2px;
  width: 27px;
}

.h-decor {
  display: inline-block;
  height: 3px;
  width: 53px;
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
}

.footer-bottom {
  padding: 11px 0;
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
  font-size: 15px;
  line-height: 26px;
  font-weight: 300;
}

.fa-chevron-right {
  /* font-size: 12px !important;
  margin-right: 5px; */
  color: #1a4774;
  padding-right: 12px;
  font-size: 10px;
  position: relative;
  top: -2px;
}

.qr-code h3 {
  font-size: 14px;
  margin-top: 30px;
  margin-bottom: 0;
  padding-bottom: 20px;
}

.phone {
  font-weight: 600;
  color: #1a4774;
}


.st_footer_widget_title {
  font-size: 20px;
  position: relative;
  padding-bottom: 17px;
  margin: 0;
  margin-bottom: 30px;
  margin-top: -4px;
  color: #001b4b !important;
  font-weight: 600;
}

.st_footer_widget_title:before {
  content: '';
  position: absolute;
  height: 1px;
  width: 100%;
  max-width: 170px;
  background-color: #eaeaea !important;
  bottom: 0;
  left: 0;
}

.st_footer_widget_nav {
  margin-top: -5px;
}

.st_footer_widget_nav li {
  position: relative;
  margin-bottom: 15px;
}

.st_footer_widget_nav li:last-child {
  margin-bottom: 0;
}

.st_footer_widget_nav a {
  display: inline-block;
  padding-left: 15px;
  position: relative;
  color: #1a4774 !important;
  font-weight: 500;
}

.st_footer_widget_nav a:hover {
  color: #001b4b !important;
  cursor: pointer;
  text-decoration: none;
  margin-left: 5px;
  transition: 0.3s;
}

.st_footer_widget_nav i {
  position: absolute;
  left: 0;
  top: 6px;
  font-size: 12px;
}

.st_mp0 {
  list-style: none;
  margin: 0;
  padding: 0;
}