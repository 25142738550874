.left-main {
  position: fixed;
  left: 10px;
  top: 30%;
  background: transparent;
  width: 95px;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  cursor: pointer;
}
.left-main:hover {
  cursor: pointer;
}
.left-covid-info {
  box-sizing: border-box;
  border: 0;
  margin-top: 10px;
  overflow: hidden;
  z-index: 99;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  border-radius: 5px;
  width: 70px;
  height: 80px;
  background-color: #e92125;
}
.left-covid-people {
  box-sizing: border-box;
  border: 0;
  margin-top: 10px;

  overflow: hidden;
  z-index: 99;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  border-radius: 5px;
  width: 70px;
  line-height: normal;
  height: 87px;
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
}
.left-hotcall {
  box-sizing: border-box;
  border: 0;
  margin-top: 10px;

  overflow: hidden;
  line-height: 26px;
  z-index: 999;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  border-radius: 5px;
  width: 70px;
  height: 85px;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 23%);
  -webkit-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 23%);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.23);
  background-color: #fff;
  transition: 0.5s;
}
.left-hotcall:hover {
  transition: 0.5s;
  height: 110px;
  bottom: 190px;
}
.left-img-size {
  padding: 7px 10px 0px 10px;
  width: 100%;
}
.left-text {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 900;
}
