html {
  scroll-behavior: smooth;
  scroll-padding-top: 300px;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

p {
  font-family: "Work Sans", sans-serif;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.h1customefontsize {
  font-size: 22px;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.cursor-pointer {
  cursor: pointer;
}

.listStyleOrder li {
  list-style: decimal;
}

.center-childrens {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.light-Color-Para {
  color: #787878;
}

.gray-headin {
  color: #444;
  font-weight: 600;
}

.headerBtn-red {
  margin-top: 10px;
  border-radius: 20px;
  background-color: #e92125;
  border: 1px solid transparent;
  color: #fff;
  min-width: 90px;
  padding: 5px;
  padding-left: 24px;
  padding-right: 24px;
  cursor: pointer;
}

button:focus {
  outline: transparent;
}

.headerBtn {
  margin-top: 10px;
  border-radius: 20px;
  border: 2px solid #1a4774;
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
  border: 1px solid transparent;
  color: #fff;
  min-width: 90px;
  padding: 5px 15px;
  cursor: pointer;
}

.headerBtn-remove {
  margin-top: 10px;
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
  color: #fff;
  min-width: 90px;
  padding: 5px 15px;
  cursor: pointer;
}

.headerBtn:hover {
  margin-top: 10px;
  border-radius: 20px;
  border: 2px solid #1a4774;
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
  border: 1px solid transparent;
  color: #fff;
  min-width: 90px;
  padding: 5px 15px;
  cursor: pointer;
}

.headerBtn-red:hover {
  background-color: #1a4774;
  color: #fff;
  padding: 5px;

  padding-left: 24px;
  padding-right: 24px;
}

.blue-heading {
  color: #1a4774 !important;
}

.h-decor {
  display: inline-block;
  height: 3px;
  width: 53px;
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
}

.pkg-input-radio {
  border: 20px solid #fff;
  width: 25px;
  height: 25px;
}

.subHeading {
  margin-bottom: 17px;
  color: #494949;
  font-size: 22px;
  line-height: 1.2em;
  font-weight: 500;
  font-family: "Roboto Condensed", sans-serif;
  letter-spacing: 1px;
}

.sub-heading {
  margin-bottom: 17px;
  font-size: 22px;
  line-height: 1.2em;
  font-weight: 500;
  font-family: "Roboto Condensed", sans-serif;
  letter-spacing: 1px;
  color: #1a4774;
}

.serviceCard {
  background: #fff;
  width: 100%;
  height: 200px;
  border: 1px solid lightgray;
  display: flex;
  flex-direction: column;
}

.serviceCard-Active {
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
  width: 100%;
  height: 200px;
  border: 1px solid lightgray;
  display: flex;
  flex-direction: column;
}

@media (min-width: 1200px) {
  .containenavcustome {
    max-width: 1200px !important;
  }
}

.serviceCard-Active>.serviceCard-heading {
  color: #fff;
}

.serviceCard:hover {
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
}

.serviceCard-circle {
  background-color: #e92125;
  border-radius: 50%;
  width: 88px;
  height: 88px;
  font-size: 40px;
  box-shadow: 0 0 0 50px rgb(255 255 255 / 0%);
  transition: 0.5s;
}

.serviceCard:hover>.serviceCard-circle {
  box-shadow: 0 0 0 5px rgb(233 33 37 / 50%);
}

.serviceCard:hover>.serviceCard-heading {
  color: #fff;
}

.serviceCard-heading {
  font-size: 14px;
  line-height: 1em;
  font-weight: 600;
  font-family: "Roboto Condensed", sans-serif;
  color: #444;
}

.mobile-lab-card {
  background: #e92125;
  display: flex;
  font-weight: bolder;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  padding: 10px;
}

.test {
  background-image: url("./Statics/Images/contact-bg.png");
}

.about-para {
  color: #1a4774 !important;
  line-height: 18px;
  font-weight: 600;
  font-family: "Roboto Condensed", sans-serif;
  letter-spacing: 0.6px;
  font-size: 14px;
}

.about-para:hover {
  color: #787878 !important;
}

.video-btn {
  position: absolute;
  overflow: hidden;
  z-index: 1;
  bottom: 35px;
  left: 15px;
  top: 65%;
  padding: 20px 76px 20px 30px;
  height: 61px;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  border-radius: 0 30px 30px 0;
  color: #fff;
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
  text-decoration: none;
  cursor: pointer;
}

.video-btn span:last-child {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  top: 10px;
  right: 10px;
  width: 41px;
  height: 41px;
  font-size: 16px;
  border-radius: 50%;
  background-color: #ffffff;
  color: #1a4774;
  box-shadow: 0 0 0 5px rgb(255 255 255 / 20%);
  transition: 0.5s;
}

.hvr-icon-forward {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.hvr-icon-forward .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.hvr-icon-forward:hover .hvr-icon,
.hvr-icon-forward:focus .hvr-icon,
.hvr-icon-forward:active .hvr-icon {
  -webkit-transform: translateX(6px);
  transform: translateX(6px);
}

.footer-icon-item {
  margin-top: 20px;
  color: #1a4774 !important;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: 4px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-icon-item:hover {
  border-radius: 70%;
  border: 4px solid lightgray;
}

.footer-social-links {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.footer-input-icon {
  color: #1a4774;
  position: absolute;
  font-size: 20px;
  top: 10px;
  right: 10px;
}

input:focus {
  outline: gray;
}

.dashboardMainHaeding-container {
  background: rgba(26, 71, 116, 0.85);
  width: 100%;
}

.dashboardMainHeading-text {
  font-size: 52px;
  color: #ffff;
  font-weight: 700;
  line-height: 1em;
}

@media (max-width: 575.98px) {
  .dashboardMainHeading-text {
    font-size: 40px !important;
  }
}

@media (max-width: 400px) {
  .dashboardMainHeading-text {
    font-size: 35px !important;
  }
}

.dashboardBtnList-item {
  border-radius: 3px;
  cursor: pointer;
  width: 100%;
}

.dashboardBtnList-item a {
  font-weight: 500;
}

.default-color-and-hover {
  background-color: #dfeffe;
}

.default-color-and-hover a {
  color: #1a4774 !important;
}

.default-color-and-hover:hover {
  background: #1a4774 !important;
}

.default-color-and-hover:hover a {
  color: #fff !important;
}

.dashboardBtnList-item-active {
  background: #1a4774 !important;
}

.dashboardBtnList-item-active a {
  color: #fff !important;
}

.dashboardBtnList-itemgallery-active {
  background: #e92125 !important;
}

.dashboardBtnList-itemgallery-active a {
  color: #fff !important;
}

.default-color-and-hovergallery {
  background-color: #dfeffe;
}

.default-color-and-hovergallery a {
  color: #787878 !important;
}

.default-color-and-hovergallery:hover {
  background: #e92125 !important;
}

.default-color-and-hovergallery:hover a {
  color: #fff !important;
}

.patient-profile-container {
  margin-top: 40px;
}

.patient-img-container {
  border-radius: 2px;
  width: 100%;
  border: 1px #ccc solid;
  background: #fff;
  margin-bottom: 15px;
  float: left;
  overflow: hidden;
  height: 208px;
}

.patient-dashboard-text-field-container {
  display: flex;
  flex-direction: column;
}

/* .patient-dashboard-text-field-label span {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  color: #999;
  width: 100%;
  height: auto;
  overflow: hidden;
  float: left;
  padding-bottom: 0px;
}
.patient-dashboard-text-field-value span {
  font-size: 17px;
  color: #000;
  width: 100%;
  height: auto;
  overflow: hidden;
  float: left;
  padding-bottom: 0px;
} */
.edit-patient-btn {
  background: #e92125 !important;
  color: #fff !important;
}

.edit-patient-btn a {
  color: #fff !important;
}

.edit-patient-btn:hover {
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%) !important;
  color: #fff !important;
}

.add-appointment-btn {
  background: green !important;
  color: #fff !important;
  width: auto;
}

.add-appointment-btn a {
  color: #fff !important;
}

.add-appointment-btn:hover {
  background: #e92125 !important;
  color: #fff !important;
}

.Toastify__toast--error {
  background-color: #e92125 !important;
}

.patient-dashboard-heading {
  font-family: "Roboto Condensed", sans-serif;
  line-height: 1.55em;
  margin-bottom: 18px;
  font-weight: 600;
  color: #444;
}

.appointment-reschdule-btn {
  background-color: #e92125 !important;
  align-self: center;

}

.appointment-reschdule-btn a {
  color: #fff !important;
}

.order-pay-btn {
  background-color: green !important;
}

.order-pay-btn a {
  color: #fff !important;
}

.appointment-reschdule-btn:hover {
  background-color: #d91c20 !important;
}

.patient-appointment-table-heading {
  color: #787878 !important;
  font-family: "Roboto", sans-serif;
}

.popUpModal>div>div {
  background: transparent;
  border: 0px solid rgba(0, 0, 0, 0.2);
  border-bottom: 0px solid transparent !important;
  width: fit-content;
  padding: 5px;
}

.popUpModal>div>div>div>button {
  position: absolute;
  font-size: 40px;
  right: 5px;
  top: 0px;
}

.telemedicine-list>li {
  font-weight: 400;
  line-height: 28px;
}

.telemedicine-list-heading {
  font-family: "Roboto Condensed", sans-serif;
  line-height: 1em;
  margin-bottom: 20px;
  font-weight: 600;
  color: #444;
}

.covid-info-list>li {
  list-style: circle;
  margin-left: 20px;
}

.covid-info-list-heading {
  color: #787878;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  line-height: 27px;
}

.covidbox {
  width: 100%;
  height: auto;
  overflow: hidden;
  float: left;
  background: #eaf3fc;
  border-radius: 3px;
  padding: 25px;
  min-height: 434px;
}

.covidbox2 {
  width: 100%;
  height: auto;
  overflow: hidden;
  float: left;
  background: #eaf3fc;
  border-radius: 3px;
  padding: 25px;
  min-height: 700px;
}

.banerWithTextHeading {
  font-size: 52px;
  color: #ffff;
  font-weight: 700;
  margin-bottom: 5px;
}

.banerWithTextSubHeading {
  color: #fff;
  font-weight: 700;
  text-decoration: none;
}

.bookCovidTestLabel {
  display: block;
  color: #003242;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
}

.bookCovidTestText {
  border-color: transparent;
  border: 2px solid #e5e5e5;
  padding: 15px 25px;
  background: #fff;
  color: #7a8a9e;
  font-size: 14px;
  margin: 0;
  border-radius: 0px;
  width: 100%;
}

.bookCovidTestBtn {
  border-color: transparent;
  border: 2px solid #e5e5e5;
  padding: 10px 25px;
  background: #fff;
  color: #7a8a9e;
  font-size: 14px;
  margin: 0;
  border-radius: 0px;
  width: 100%;
}

.bookCovidTestBtn:hover {
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
  color: #fff;
  border: 1px solid transparent;
}

.aboutUsCheckIcon {
  border: 2px solid #f0f3f4;
  height: 50px;
  width: 60px;
  text-align: center;
  line-height: 50px;
  color: #1a4774;
  border-radius: 50%;
  margin-right: 20px;
}

.ourMissionContainer {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  box-shadow: 0 0 8px rgb(0 0 0 / 6%);
  padding: 30px 45px;
  min-height: 303px;
}

.ouMissioniconContainer {
  text-align: center;
  margin: 0 auto;
  display: inline-block;
  margin-bottom: 23px;
}

.ourMissionIcon {
  width: 60px;
  height: 50px;
  object-fit: contain;
  vertical-align: baseline;
}

.missionTitle {
  font-size: 20px;
  font-weight: 700;
  display: block;
  margin-bottom: 10px;
}

.ourmissionBottomImg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.labTeamBtn {
  width: auto;
  float: left;
  padding: 10px 15px;
  margin-right: 8px;
  border-radius: 3px;
  color: #787878 !important;
  background: #f2f2f2;
  font-size: 13px;
  cursor: pointer;
}

.labTeamBtnActive {
  width: auto;
  float: left;
  padding: 10px 15px;
  margin-right: 8px;
  border-radius: 3px;
  font-size: 13px;
  cursor: pointer;
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%) !important;
}

.labTeamBtn:hover {
  color: #fff !important;
  cursor: pointer;
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%) !important;
}

.containerWithShadow {
  background-color: #fff;
  -webkit-box-shadow: -4px 6px 31px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -4px 6px 31px -20px rgba(0, 0, 0, 0.75);
  box-shadow: -4px 6px 31px -20px rgba(0, 0, 0, 0.75);
  border-radius: 5px;
}

.administratorDoctorSLotsSection {
  display: flex;
  flex-direction: row;
}

.cartTable tr td {
  border: 1px #eee solid;
  padding: 7px 12px;
}

.react-bootstrap-table-pagination-list>ul {
  display: flex !important;
  justify-content: flex-end !important;
}

/* .smallVideoContainer{
  position: fixed !important;
  bottom: 0% !important;
  right: 0% !important;
  z-index: 9999;
} */
.room {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.callContainer {
  display: flex;
  align-items: center;
}

.notificationContainer {
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 5%);
  -webkit-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 5%);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%) !important;
  color: #fff;
}

.chatbutton {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

.mf-content {
  padding: 12px 17px 13px;
  display: inline-block;
  max-width: 80%;
}

.mf-date {
  display: block;
  color: #b3b3b3;
  margin-top: 7px;
}

.mf-date>i {
  font-size: 14px;
  line-height: 100%;
  position: relative;
  top: 1px;
}

.incoming_msg_img {
  display: inline-block;
  width: 8%;
}

img {
  max-width: 100%;
}

.inbox_msg {
  border: 1px solid #c4c4c4;
  clear: both;
  overflow: hidden;
}

.paymentSuccessBackground {
  background: url("./Statics/Images/paymentSuccess.jpg");
  background-position: right;
  background-size: 80% 100%;
  background-repeat: no-repeat;
}

.paymentFailBackground {
  background: url("./Statics/Images/paymentFail.jpg");
  background-position: right;
  background-size: 80% 100%;
  background-repeat: no-repeat;
}

.disableBtn {
  pointer-events: none !important;
  cursor: default !important;
}

.chat-frame {
  position: relative;
  height: 100%;
  width: 100%;
  border: transparent;
}

.contact-info-box .title {
  position: relative;
  border-bottom: 2px solid #ececec;
  padding-bottom: 25px;
  font-weight: 600;
  margin-bottom: 40px;
  margin-right: 50px;
}

.contact-info-box .title::after {
  content: "";
  left: 0;
  bottom: -3px;
  width: 80px;
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
  height: 4px;
  position: absolute;
}

.contact-info-box li,
.contact-info-box li a {
  font-size: 18px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #003242;
  font-weight: 500;
}

.contact-info-box li i {
  font-size: 24px;
  left: 0;
  top: 0;
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.contact-info-box li {
  padding-left: 10px;
}

.contact-info-box .icon {
  margin-right: 20px;
  -webkit-transform: translateY(5px);
  -ms-transform: translateY(5px);
  transform: translateY(5px);
}

.contact-info-box .content {
  line-height: 28px;
  font-weight: 500;
}

.contact-info-box li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.contact-info-box ul li:not(:first-child) {
  margin-top: 30px;
}

.contact-info-box li:first-child .icon {
  margin-right: 26px;
}

.contact-info-box li:first-child {
  margin-bottom: -5px;
}

.selectsect {
  border-color: transparent;
  border: 2px solid #e5e5e5;
  padding: 19px 8px;
  background: #fff;
  color: #7a8a9e;
  font-size: 14px;
  margin: 0;
  border-radius: 0px;
  width: 100%;
}

.contact-form-touch .contact-form-getin input,
.contact-form-touch .contact-form-getin textarea {
  border-color: transparent;
  border: 2px solid #e5e5e5;
  padding: 15px 25px;
  background: #fff;
  color: #7a8a9e;
  font-size: 14px;
  margin: 0;
  border-radius: 0px;
  width: 100%;
}

.contact-form-touch .contact-form-getin .form-group {
  margin-bottom: 25px;
}

.contact-form-touch .title {
  font-weight: 700;
  margin-bottom: 40px;
  color: #1a4774;
}

.contact-form-touch .contact-form-getin textarea {
  min-height: 145px;
}

.contact-form-touch .contact-form-getin label {
  display: block;
  color: #003242;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
}

.contact-form-touch .contact-form-getin .form-group {
  position: relative;
  margin-bottom: 35px;
}

.contact-info-box-wrap .contact-info-box ul {
  padding: 0;
}

.testScroll::-webkit-scrollbar {
  width: 10px;
}

.testScroll::-webkit-scrollbar-thumb {
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%) !important;
  border-radius: 2px !important;
}

/* ::-webkit-scrollbar-thumb {
  background: linear-gradient( 
90deg, #476b8f 0%, #1a4774 100%);
  border-radius: 1px;

  background-color: red !important;
} */

/* Inner Page Select Handler Css */
.dashboardBtnList-item-doc-active {
  border: 1px solid #1a4774 !important;
  border-radius: 0px !important;
  text-decoration: none;
}

.dashboardBtnList-item-doc-active a {
  color: #fff !important;
  text-decoration: none;
}

.default-color-and-hover-doc a {
  color: grey !important;
  text-decoration: none;
}

.default-color-and-hover-doc:hover {
  border-radius: 0px !important;
  border: 1px solid #1a4774 !important;
}

.default-color-and-hover-doc:hover a {
  text-decoration: none;
}

/* Ahsan Work Css */
.about-thumb-wrap {
  position: relative;
  padding-right: 40px;
  /* margin-bottom: 40px; */
}

.about-thumb img {
  margin-bottom: 25px;
}

.about-content p {
  margin-bottom: 10px;
}

.about-thumb-wrap .about-content {
  position: absolute;
  background: #fff;
  padding: 30px 35px;
  box-shadow: 0 16px 32px 0px #048ec538;
  bottom: 70px;
  left: -50px;
}

.content .title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
  line-height: 34px;
}

.about-content::after {
  content: "";
  right: 0;
  bottom: -18px;
  border-top: 18px solid #fff;
  position: absolute;
  border-left: 18px solid transparent;
}

.about-content .content {
  font-size: 16px;
  font-weight: 700;
}

.dots {
  position: absolute;
  top: 20px;
  right: 25px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.dots span {
  height: 10px;
  width: 10px;
  background: #1a4774;
  box-shadow: 0 4px 8px 0px rgba(4, 142, 197, 0.349);
  border-radius: 50%;
}

.dots span:first-child {
  margin-right: 10px;
}

.about-list {
  padding-bottom: 0;
  border: none;
  margin-bottom: 30px;
  margin-top: 15px;
}

.about-content .content .counter {
  font-size: 80px;
  font-weight: 700;
}

.serachsect {
  width: 100%;
  height: 45px;
  overflow: hidden;
  float: left;
  border: 1px #eee solid;
  padding: 0px 12px;
  margin-bottom: 15px;
  font-size: 13px;
}

.curs {
  cursor: pointer;
}

.react-tel-input .form-control {

  width: 100% !important;
}

.fontsizecus {
  font-size: 14px !important;
}

.st-sticky-header {
  position: fixed;
  z-index: 200;
  width: 100%;
  top: 0;
  left: 0;
}

.opt {
  color: #1a4774;
  cursor: pointer !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.opt:hover {
  background-color: linear-gradient(90deg, #476b8f 0%, #1a4774 100%) !important;
}


.ReactFlagsSelect-module_selectBtn__19wW7 {
  background: #fff !important
}


.dashboardBtnList-item-disbaled {
  border-radius: 3px;
  width: 100%;
  background-color: green !important;
  color: #fff;
  height: 100%
}

.coll{
  color:linear-gradient(90deg, #476b8f 0%, #1a4774 100%) !important;
}
.coll:hover{
  color:linear-gradient(90deg, #476b8f 0%, #1a4774 100%) !important;
}

button[disabled]{
  border: 1px solid #999999 !important;
  background-color: #cccccc !important;
  color: #666666 !important;
}
.ql-indent-1{
  color:#003242 !important;
  text-align: center !important;
  margin-bottom: 20px !important;
}

#someselector > li{
  list-style: unset !important;
}
#set * {
    padding: initial;
    box-sizing: inherit !important;
}
#setpripol * {
    padding: inherit;
    box-sizing: inherit !important;
}
#setpripol1 * {
   
    box-sizing: inherit !important;
}

.table-responsive {
 max-height: 500px!important;
 scrollbar-width:10px!important;

}


.table-responsive::-webkit-scrollbar{
    background-color: #dfeffe!important;
    height: 10px!important;
    width:10px!important;
}
thead{
  position: sticky!important;
  top:0!important;
 z-index: 1!important;
background-color: #dfeffe!important;
color:#3b6188!important;
}
table  { border-collapse: collapse; width: 100%; }



.linkhov:hover{
  color:$themepink-color !important;
  text-decoration: none !important;
  transition:ease-in  0.7s !important;
}
.Typograpy_Text_h1{
font-weight: 500 !important;
text-transform: capitalize;
}
.Typograpy_Text_h4{
font-size: 18px;
font-weight: 500 !important;
text-transform: capitalize;
}


@media (max-width: 575.98px) 

{
.Typograpy_Text_h1{
  font-size: 40px !important;
}
.Typograpy_Text_h4{
  font-size: 12px !important;
}

}
@media (max-width: 400px) 
{

.Typograpy_Text_h1{
  font-size: 35px !important;
}
.Typograpy_Text_h4{
  font-size: 12px !important;
}

}
