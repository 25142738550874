
.contact-info-box .title {
    position: relative;
    border-bottom: 2px solid #ececec;
    padding-bottom: 25px;
    font-weight: 600;
    margin-bottom: 40px;
    margin-right: 50px;
  }
  
  .contact-info-box .title::after {
    content: "";
    left: 0;
    bottom: -3px;
    width: 80px;
    background: linear-gradient( 90deg, #476b8f 0%, #1a4774 100%);
    height: 4px;
    position: absolute;
  }
  
  .contact-info-box li, .contact-info-box li a {
    font-size: 18px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: #003242;
    font-weight: 500;
  }
  
  .contact-info-box li i {
    font-size: 24px;
    left: 0;
    top: 0;
    background: linear-gradient( 90deg, #476b8f 0%, #1a4774 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
  }
  
  .contact-info-box li {
    padding-left: 10px;
  }
  
  .contact-info-box .icon {
    margin-right: 20px;
    -webkit-transform: translateY(5px);
    -ms-transform: translateY(5px);
    transform: translateY(5px);
  }
  
  .contact-info-box .content {
    line-height: 28px;
    font-weight: 500;
  }
  
  .contact-info-box li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  
  .contact-info-box ul li:not(:first-child) {
    margin-top: 30px;
  }
  
  .contact-info-box li:first-child .icon {
    margin-right: 26px;
  }
  
  .contact-info-box li:first-child {
    margin-bottom: -5px;
  }
  
  .selectsect{
    border-color: transparent;
    border: 2px solid #e5e5e5;
    padding:19px 8px;
    background: #fff;
    color: #7a8a9e;
    font-size: 14px;
    margin: 0;
    border-radius: 0px;
    width: 100%;
  }
  
  .contact-form-touch .contact-form-getin input, .contact-form-touch .contact-form-getin textarea {
    border-color: transparent;
    border: 2px solid #e5e5e5;
    padding: 15px 25px;
    background: #fff;
    color: #7a8a9e;
    font-size: 14px;
    margin: 0;
    border-radius: 0px;
    width: 100%;
  }
  
  .contact-form-touch .contact-form-getin .form-group {
    margin-bottom: 25px;
  }
  
  .contact-form-touch .title {
    font-weight: 700;
    margin-bottom: 40px;
    color: #1a4774;
  }
  
  .contact-form-touch .contact-form-getin textarea {
    min-height: 145px;
  }
  
  .contact-form-touch .contact-form-getin label {
    display: block;
    color: #003242;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
  }
  
  .contact-form-touch .contact-form-getin .form-group {
    position: relative;
    margin-bottom: 35px;
  }
  
  .contact-info-box-wrap .contact-info-box ul {
    padding: 0;
  }