
  /* .heading {
    font-size: 17px;
    color: #fff;
    font-weight: 600;
    transition: 0.25s;
  
  } */
  /* .card-discription {
    font-size: 11px;
    line-height: 20px !important;
    font-family: "Work Sans", sans-serif;
  } */
  .card-size-home {
    height: 360px !important;
  }
  
  .designation {
    font-size: 11px;
    float: left;
    text-align: center;
  }
  .magnific-imgs h3 {
    text-align: center;
    font-size: 12px!important;
  }
  .magnific-imgs img {
    height:200px !important;
  }