.covidTeamCardImg {
    border-radius: 50%;
    width: 170px;
    height: 170px;
    text-align: center;
    position: relative;
  }
  .covidTeamCardText {
    margin-bottom: 0;
    display: inline-block;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .socialmediasIconCovidTeam {
    display: flex;
    flex-direction: row;
  }
  .CovidTeamCardBtn {
    width: 100px;
    display: block;
    height: auto;
    overflow: hidden;
    float: left;
    text-align: center;
    color: #fff;
    background: #1a4774;
    border-radius: 50px;
    padding: 4px 3px;
    font-size: 13px;
    line-height: 27px;
  }
  .CovidTeamCardBtn:hover {
    background: #133b62;
    color: #fff !important;
  }
  .covidTeamImgIcon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 41%;
    transform: translateX(-50%);
    background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .covidTEamHeadingCard{
    padding-top: 20px;
  }
  