/* .size-slider {
  width: 100% !important;
  height: 450px !important;
}
.carousel-caption {
  background: linear-gradient( to bottom, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100% );
  border-radius: 5px;
  left: 5% !important;
  width: 55% !important;
}
.heading-Slider {
  font-size: 42px;
  font-family: "Roboto Condensed", sans-serif;
  text-transform: uppercase ;
}
.discription-Slider {
  font-size: 16px;
  font-family: "Roboto Condensed", sans-serif;
  letter-spacing: 0.03em;
} */

@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap");
.caption {
  width: fit-content;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0.3) 100%
  );
  padding: 10px;
  border-radius: 5px !important;
  color: white;
  animation-duration: 1s;
  animation-delay: 2s;
}

.caption h2 {
  animation-duration: 1s;
  animation-delay: 2s;
}

.caption p {
  animation-duration: 1s;
  animation-delay: 2.2s;
}

.caption a {
  animation-duration: 1s;
  animation-delay: 2.4s;
}

.cen {
  display: flex;
  flex-direction: column !important;
}

.slider-wrapper {
  position: relative;
}
.slider-wrapper .slider-overlay {
  position: absolute;
  left: 5%;
  top: 50%;
  width: fit-content;
  height: 55%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: inherit;
  justify-content: center;
  /* background: rgba(134, 134, 134, 0.2); */
}

.zoom-in-zoom-out {
  animation: opacity 500ms ease-in-out 0s, visibility 500ms ease-in-out 0s;
  
}







.fadeInDown {
  animation-name: fadeInDown;
  -webkit-animation-duration: 0.9s;
  animation-delay:50s;
  animation-duration: 0.9s;
  animation-timing-function: linear;
}

  
  
@keyframes fadeInDown {
  0% {
      opacity: 0;
      -webkit-transform: translateY(-20px);
  }
  100% {
      opacity: 1;
      -webkit-transform: translateY(0);
  }
    }
  
  

    @keyframes fadeInUp {
      from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 60%, 0);
      }
    
      to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
      }
    }
    
    .fadeInUp {
      animation-name: fadeInUp;
      -webkit-animation-duration: 0.9s;
      animation-delay:50s;
      animation-duration: 0.9s;
      animation-timing-function: linear;
    }










.wrap {
  height: 200px;
  width: 300px;
  overflow-y: hidden;
  position: relative;
}

.typography_Heading {
  font-family: "Montserrat", sans-serif;
  font-size: calc(32px + .5vw) !important;
  font-weight: 600 !important;
  text-transform: uppercase;
}
.img-inline-res {
  width: calc(100% - 70%);
  margin: 0px 15px 0px 0px !important;
}

.btn-custom-animation:hover {
  border: 1px solid #0066b3 !important;
  color: #000 !important;
  background-color: #fff !important;
}

.typograpy_title {
  font-size: 16px;
  color: #fff;
  font-weight: 400 !important;
}
.typograpy_title0 {
  font-size: 2vw !important;
  color: #fff;
  font-family: "Dancing Script", cursive !important;
}

.ball {
  animation: opacity 0.5s, bounce 2.5s infinite alternate;
  -webkit-animation: opacity 0.5s, bounce 2.5s infinite alternate;
}
@keyframes bounce {
  from {
    transform: translateY(-20px);
  }
  to {
    transform: translateY(0px);
  }
}
@-webkit-keyframes bounce {
  from {
    transform: translateY(-20px);
  }
  to {
    transform: translateY(0px);
  }
}



.btn_text_typography {
  font-weight: 600 !important;
  text-transform: uppercase;
  font-size: 12px !important;
  padding: 12px 30px !important;
}

/* Slick Dots Ka Color Ki Css */
.slick-dots {
  position: absolute;
  list-style: none;
  display: block;
  text-align: center;
  margin-bottom: 60px;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 10px;
  margin-left: 15px;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  display: block;
  height: 10px;
  width: 10px;
  /* outline: #e1e5e9 !important; */
  color: #ffffff !important;
  border: 2px solid #ffffff !important;
  border-radius: 50% !important;
  line-height: 0;
  font-size: 0;
  padding: 5px;
  cursor: pointer;
}
  
  .slick-dots li button:before {
    position: absolute;
    content: "•";
    font-family: "slick";
    font-size: 10px;
    padding-top: 7px;
    padding-right: 6px;
    line-height: 2px;
    text-align: center;
    color: transparent !important;
    border-radius: 10px;
  }
  .slick-dots li.slick-active button:before {
    color:#ffffff !important;
    opacity: 1 !important;
  }
.slick-initialized .slick-slide.slick-active {
  z-index: 1 !important;
}

