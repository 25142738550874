.thrdoptions {
  width: 100%;
  height: auto;
  overflow: hidden;
  float: left;
}
.facebookpostd24 {
  width: 100%;
  height: auto;
  overflow: hidden;
  float: left;
  padding: 0px 12px;
  position: relative;
  z-index: 900;
  margin-top: -30px;
}
.facebookpostd24_info {
  width: 100%;
  height: auto;
  overflow: hidden;
  float: left;
  background: #f9f9f9;
  border-radius: 2px;
  padding: 15px;
}
.newsbg {
  width: 100%;
  height: auto;
  overflow: hidden;
  float: left;
  background-size: cover !important;
  min-height: 450px;
  position: relative;
}
.maintitle_social {
  color: #fff;
  position: absolute;
  top: inherit;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 65px 17px 15px;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1)
  );
}

.post_fetureimage {
  width: 100%;
  height: 250px;
  overflow: hidden;
  float: left;
  border-radius: 1px;
  position: relative;
}

.list_fbpost {
  width: 100%;
  height: auto;
  overflow: hidden;
  float: left;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 25px;
  padding: 8px;
  background: #fdfdfd;
}

.post_fetureimage img {
  width: 100%;
}
.facebookpostd {
  width: 100%;
  height: auto;
  overflow: hidden;
  float: left;
  background: #fff;
  padding: 12px;
  position: relative;
  border: 1px;
}
.postdates {
  position: absolute;
  top: 11px;
  right: 7px;
  letter-spacing: -0.9px;
  background-color: rgba(14, 86, 132, 0.9);
  width: 68px;
  text-align: center;
  padding: 12px 3px;
  color: #fff;
  line-height: 18px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.descriptn_news {
  width: 100%;
  height: 75px;
  overflow: hidden;
  float: left;
  font-size: 15px !important;
  text-align: justify;
  line-height: 19px;
}
