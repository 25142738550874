@font-face {
  font-family: 'SutonnyMJ';
  src: url(../../../../SutonnyMJ-Regular.ttf);
  
}
.newsectionm {
  width: 100%;
  height: auto;
  overflow: hidden;
  float: left;
  border: 1px #eee solid;
  background: #fff;
  padding: 12px;
  border-radius: 2px;
  margin-bottom: 30px;
}
.news_left {
  width: 40%;
  height: auto;
  overflow: hidden;
  float: left;
  padding-right: 10px;
}
.news_right {
  width: 60%;
  height: auto;
  overflow: hidden;
  float: left;
  padding-left: 10px;
}
.newsimagescttn {
  width: 100%;
  border: 1px #ccc solid;
  float: left;
  height: 151px;
  overflow: hidden;
}

@media (max-width: 768px) {
  .news_left {
    width: 65%;
    height: auto;
    overflow: hidden;
    margin: auto;
    display: block;
    text-align: center;
  }
  .news_right {
    width: 100%;
    height: auto;
    overflow: hidden;
    float: left;
    padding-top: 12px;
  }
}

.banglafont {
  font-family: "SutonnyMJ" !important;
  font-size: 18px !important;
}
.descriptn_news {
  width: 100%;
  height: 75px;
  overflow: hidden;
  float: left;
  font-size: 15px !important;
  text-align: justify;
  line-height: 19px;
}
.news-meta ul {
  display: flex;
  font-size: 14px;
  padding: 0;
}
.news-meta ul li {
  list-style: none;
}
.news-meta ul a {
  color: #003242;
  font-weight: 700;
}
.news-meta ul a i {
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.news-meta ul li:last-child {
    margin-left: 20px;
  }
  
.banglafont {
    font-family: 'SutonnyMJ'!important;
    font-size: 18px!important;
    color: #0e5684!important;  
}
.newsbtn  {
  color: #0e5684!important;
  clear: both !important;
  width: 100% !important;
  float: left !important;
}
.newsbtn:hover {
  color: #ed1c24!important;
}