.right-main {
  position: fixed;
  right: 0;
  top: 30%;
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
  width: 95px;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  cursor: pointer;
}
.right-item {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.right-icon {
  font-size: 24px;
  line-height: 1.3em;
  color: #ef6366;
}
.right-para {
  color: #fff;
  text-align: center;
  font-size: 10px;
  line-height: 13px;
  font-family: "Roboto Condensed", sans-serif;
  text-transform: uppercase;
  padding-left: 2px;
}
.emergency-data-refrence {
  position: relative;
}
.detail-right-div-emergency {
  width: 400px;
  height: fit-content;
  background-color: white;
  position: absolute;
  top: 30%;
  right: 100%;
  display: none;
}
.right-hover-active:hover .detail-right-div-emergency {
  display: block;
  -webkit-box-shadow: -2px 20px 5px -9px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: -2px 20px 5px -9px rgba(0, 0, 0, 0.28);
  box-shadow: -2px 20px 5px -9px rgba(0, 0, 0, 0.28);
}
.btn-position-emergency-call {
  display: flex;
  justify-content: flex-end;
}
.google-map {
  width: 500px;
  max-width: 100vw;
  height: 312px;
}
.detail-right-div-map {
  width: 500px;
  height: fit-content;
  background-color: white;
  position: absolute;
  top: 0%;
  right: 100%;
  display: none;
}
.right-hover-active:hover .detail-right-div-map {
  display: block;
  -webkit-box-shadow: -2px 20px 5px -9px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: -2px 20px 5px -9px rgba(0, 0, 0, 0.28);
  box-shadow: -2px 20px 5px -9px rgba(0, 0, 0, 0.28);
}
.right-main {
  position: fixed;
  right: 0;
  top: 30%;
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
  width: 95px;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  cursor: pointer;
}
.right-item {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.right-icon {
  font-size: 24px;
  line-height: 1.3em;
  color: #ef6366;
}
.right-para {
  color: #fff;
  text-align: center;
  font-size: 10px;
  line-height: 13px;
  font-family: "Roboto Condensed", sans-serif;
  text-transform: uppercase;
  padding-left: 2px;
}
.emergency-data-refrence {
  position: relative;
}
.detail-right-div-emergency {
  width: 400px;
  height: fit-content;
  background-color: white;
  position: absolute;
  top: 30%;
  right: 100%;
  display: none;
}
.right-hover-active:hover .detail-right-div-emergency {
  display: block;
  -webkit-box-shadow: -2px 20px 5px -9px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: -2px 20px 5px -9px rgba(0, 0, 0, 0.28);
  box-shadow: -2px 20px 5px -9px rgba(0, 0, 0, 0.28);
}
.btn-position-emergency-call {
  display: flex;
  justify-content: flex-end;
}

.google-map {
  width: 500px;
  max-width: 100vw;
  height: 312px;
}

.avatar-whatsapp {
  width: 52px;
  height: 52px;
  box-sizing: border-box;
  border: 3px solid #fff;
  border-radius: 50%;
  overflow: hidden;
  transform: translatey(0px);
  animation: float 6s ease-in-out infinite;
  position: fixed;
  bottom: 55px;
  right: 12px;
  z-index: 999;
  background: #25d366;
}

.avatar-whatsapp i {
  color: #fff;
  font-size: 22px;
  font-weight: lighter;
  text-align: center;
  display: block;
  line-height: 46px;
  font-weight: 600;
}

@keyframes float {
  0% {
    box-shadow: 0px 5px 10px 0px rgb(106 106 106 / 15%);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0px 5px 10px 0px rgb(106 106 106 / 15%);
    transform: translatey(-20px);
  }
  100% {
    box-shadow: 0px 5px 10px 0px rgb(106 106 106 / 15%);
    transform: translatey(0px);
  }
}

.backToTop {
  position: fixed;
  z-index: 1020;
  right: 15px;
  bottom: -25px;
  opacity: 0;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
  font-size: 18px;
  line-height: 52px;
  color: #fff;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 3px 3px rgb(0 0 0 / 10%);
  transition: all 0.25s, bottom 0.75s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
.backToTop [class*="fa"] {
  display: inline-block;
  transition: 0.5s;
}
.backToTop.visible {
  opacity: 1;
  bottom: 5px;
}
.backToTop:hover {
  box-shadow: 0 0 0 10px rgb(92 104 149 / 20%);
}
.backToTop:hover [class*="fa"] {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
}
