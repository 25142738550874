.main-testemonail{
flex-direction: column;
width: 100% !important;
height: 100% !important;

}
.testemonial-img{
    border-radius: 50%;
    width: 100px;
    height: 100px;
}
.testemonial-review{
    text-align: center;
    font-size: 15px;
    line-height: 29px;
    max-width: 400px;
    margin-bottom: 2em;
    color: #787878;
}