
.pkg-types {
    background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
    padding: 15px 20px 25px;
  }
.sidebar {
    background: linear-gradient( 90deg, #476b8f 0%, #1a4774 100%);
    padding: 15px 20px 25px;
    height: fit-content;
  }
  .sidebar ul {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: auto;
    margin-bottom: 10px;
    overflow: hidden;
  }
  
  .sidebar ul li {
    color: #ddd;
    display: block;
    position: relative;
    float: left;
    width: 50%;
    height: auto;
    /* border-bottom: 1px solid #f1f1f1; */
  }
  
  .sidebar ul li:last-child {
    border-bottom: none;
  }
  
  .sidebar ul li input[type=radio] {
    position: absolute;
    visibility: hidden;
  }
  
  .sidebar ul li label {
    display: block;
    position: relative;
    font-weight: 500;
    font-size: 14px;
    padding: 0px 0px 0px 35px;
    height: 40px;
    z-index: 9;
    cursor: pointer;
    -webkit-transition: all 0.25s linear;
    margin: 0 auto 0 auto;
  }
  
  .sidebar ul li:hover label {
    color: #fff;
  }
  
  .sidebar ul li .check {
    display: block;
    position: absolute;
    border: 3px solid #ddd;
    border-radius: 100%;
    height: 25px;
    width: 25px;
    top: 0;
    left: 0;
    z-index: 5;
    transition: border .25s linear;
    -webkit-transition: border .25s linear;
  }
  
  .sidebar ul li:hover .check {
    border: 3px solid #fff;
  }
  
  .sidebar ul li .check::before {
    display: block;
    position: absolute;
    content: '';
    border-radius: 100%;
    height: 12px;
    width: 12px;
    top: 3px;
    left: 3px;
    margin: auto;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear;
  }
  
  .sidebar input[type=radio]:checked~.check {
    border: 3px solid #fff;
  }
  
  .sidebar input[type=radio]:checked~.check::before {
    background: #fff;
  }
  
  .sidebar input[type=radio]:checked~label {
    color: #fff;
  }
  .sidebar ul h5 {
    margin: 0;
    font-size: 16px;
    color: #fff;
    margin-bottom: 20px;
}