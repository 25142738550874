@import url(https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap);

html {
  scroll-behavior: smooth;
  scroll-padding-top: 300px;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

p {
  font-family: "Work Sans", sans-serif;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.h1customefontsize {
  font-size: 22px;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.cursor-pointer {
  cursor: pointer;
}

.listStyleOrder li {
  list-style: decimal;
}

.center-childrens {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.light-Color-Para {
  color: #787878;
}

.gray-headin {
  color: #444;
  font-weight: 600;
}

.headerBtn-red {
  margin-top: 10px;
  border-radius: 20px;
  background-color: #e92125;
  border: 1px solid transparent;
  color: #fff;
  min-width: 90px;
  padding: 5px;
  padding-left: 24px;
  padding-right: 24px;
  cursor: pointer;
}

button:focus {
  outline: transparent;
}

.headerBtn {
  margin-top: 10px;
  border-radius: 20px;
  border: 2px solid #1a4774;
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
  border: 1px solid transparent;
  color: #fff;
  min-width: 90px;
  padding: 5px 15px;
  cursor: pointer;
}

.headerBtn-remove {
  margin-top: 10px;
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
  color: #fff;
  min-width: 90px;
  padding: 5px 15px;
  cursor: pointer;
}

.headerBtn:hover {
  margin-top: 10px;
  border-radius: 20px;
  border: 2px solid #1a4774;
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
  border: 1px solid transparent;
  color: #fff;
  min-width: 90px;
  padding: 5px 15px;
  cursor: pointer;
}

.headerBtn-red:hover {
  background-color: #1a4774;
  color: #fff;
  padding: 5px;

  padding-left: 24px;
  padding-right: 24px;
}

.blue-heading {
  color: #1a4774 !important;
}

.h-decor {
  display: inline-block;
  height: 3px;
  width: 53px;
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
}

.pkg-input-radio {
  border: 20px solid #fff;
  width: 25px;
  height: 25px;
}

.subHeading {
  margin-bottom: 17px;
  color: #494949;
  font-size: 22px;
  line-height: 1.2em;
  font-weight: 500;
  font-family: "Roboto Condensed", sans-serif;
  letter-spacing: 1px;
}

.sub-heading {
  margin-bottom: 17px;
  font-size: 22px;
  line-height: 1.2em;
  font-weight: 500;
  font-family: "Roboto Condensed", sans-serif;
  letter-spacing: 1px;
  color: #1a4774;
}

.serviceCard {
  background: #fff;
  width: 100%;
  height: 200px;
  border: 1px solid lightgray;
  display: flex;
  flex-direction: column;
}

.serviceCard-Active {
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
  width: 100%;
  height: 200px;
  border: 1px solid lightgray;
  display: flex;
  flex-direction: column;
}

@media (min-width: 1200px) {
  .containenavcustome {
    max-width: 1200px !important;
  }
}

.serviceCard-Active>.serviceCard-heading {
  color: #fff;
}

.serviceCard:hover {
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
}

.serviceCard-circle {
  background-color: #e92125;
  border-radius: 50%;
  width: 88px;
  height: 88px;
  font-size: 40px;
  box-shadow: 0 0 0 50px rgb(255 255 255 / 0%);
  transition: 0.5s;
}

.serviceCard:hover>.serviceCard-circle {
  box-shadow: 0 0 0 5px rgb(233 33 37 / 50%);
}

.serviceCard:hover>.serviceCard-heading {
  color: #fff;
}

.serviceCard-heading {
  font-size: 14px;
  line-height: 1em;
  font-weight: 600;
  font-family: "Roboto Condensed", sans-serif;
  color: #444;
}

.mobile-lab-card {
  background: #e92125;
  display: flex;
  font-weight: bolder;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  padding: 10px;
}

.test {
  background-image: url(/static/media/contact-bg.e9825dec.png);
}

.about-para {
  color: #1a4774 !important;
  line-height: 18px;
  font-weight: 600;
  font-family: "Roboto Condensed", sans-serif;
  letter-spacing: 0.6px;
  font-size: 14px;
}

.about-para:hover {
  color: #787878 !important;
}

.video-btn {
  position: absolute;
  overflow: hidden;
  z-index: 1;
  bottom: 35px;
  left: 15px;
  top: 65%;
  padding: 20px 76px 20px 30px;
  height: 61px;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  border-radius: 0 30px 30px 0;
  color: #fff;
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
  text-decoration: none;
  cursor: pointer;
}

.video-btn span:last-child {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  top: 10px;
  right: 10px;
  width: 41px;
  height: 41px;
  font-size: 16px;
  border-radius: 50%;
  background-color: #ffffff;
  color: #1a4774;
  box-shadow: 0 0 0 5px rgb(255 255 255 / 20%);
  transition: 0.5s;
}

.hvr-icon-forward {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
}

.hvr-icon-forward .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-timing-function: ease-out;
}

.hvr-icon-forward:hover .hvr-icon,
.hvr-icon-forward:focus .hvr-icon,
.hvr-icon-forward:active .hvr-icon {
  -webkit-transform: translateX(6px);
  transform: translateX(6px);
}

.footer-icon-item {
  margin-top: 20px;
  color: #1a4774 !important;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: 4px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-icon-item:hover {
  border-radius: 70%;
  border: 4px solid lightgray;
}

.footer-social-links {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.footer-input-icon {
  color: #1a4774;
  position: absolute;
  font-size: 20px;
  top: 10px;
  right: 10px;
}

input:focus {
  outline: gray;
}

.dashboardMainHaeding-container {
  background: rgba(26, 71, 116, 0.85);
  width: 100%;
}

.dashboardMainHeading-text {
  font-size: 52px;
  color: #ffff;
  font-weight: 700;
  line-height: 1em;
}

@media (max-width: 575.98px) {
  .dashboardMainHeading-text {
    font-size: 40px !important;
  }
}

@media (max-width: 400px) {
  .dashboardMainHeading-text {
    font-size: 35px !important;
  }
}

.dashboardBtnList-item {
  border-radius: 3px;
  cursor: pointer;
  width: 100%;
}

.dashboardBtnList-item a {
  font-weight: 500;
}

.default-color-and-hover {
  background-color: #dfeffe;
}

.default-color-and-hover a {
  color: #1a4774 !important;
}

.default-color-and-hover:hover {
  background: #1a4774 !important;
}

.default-color-and-hover:hover a {
  color: #fff !important;
}

.dashboardBtnList-item-active {
  background: #1a4774 !important;
}

.dashboardBtnList-item-active a {
  color: #fff !important;
}

.dashboardBtnList-itemgallery-active {
  background: #e92125 !important;
}

.dashboardBtnList-itemgallery-active a {
  color: #fff !important;
}

.default-color-and-hovergallery {
  background-color: #dfeffe;
}

.default-color-and-hovergallery a {
  color: #787878 !important;
}

.default-color-and-hovergallery:hover {
  background: #e92125 !important;
}

.default-color-and-hovergallery:hover a {
  color: #fff !important;
}

.patient-profile-container {
  margin-top: 40px;
}

.patient-img-container {
  border-radius: 2px;
  width: 100%;
  border: 1px #ccc solid;
  background: #fff;
  margin-bottom: 15px;
  float: left;
  overflow: hidden;
  height: 208px;
}

.patient-dashboard-text-field-container {
  display: flex;
  flex-direction: column;
}

/* .patient-dashboard-text-field-label span {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  color: #999;
  width: 100%;
  height: auto;
  overflow: hidden;
  float: left;
  padding-bottom: 0px;
}
.patient-dashboard-text-field-value span {
  font-size: 17px;
  color: #000;
  width: 100%;
  height: auto;
  overflow: hidden;
  float: left;
  padding-bottom: 0px;
} */
.edit-patient-btn {
  background: #e92125 !important;
  color: #fff !important;
}

.edit-patient-btn a {
  color: #fff !important;
}

.edit-patient-btn:hover {
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%) !important;
  color: #fff !important;
}

.add-appointment-btn {
  background: green !important;
  color: #fff !important;
  width: auto;
}

.add-appointment-btn a {
  color: #fff !important;
}

.add-appointment-btn:hover {
  background: #e92125 !important;
  color: #fff !important;
}

.Toastify__toast--error {
  background-color: #e92125 !important;
}

.patient-dashboard-heading {
  font-family: "Roboto Condensed", sans-serif;
  line-height: 1.55em;
  margin-bottom: 18px;
  font-weight: 600;
  color: #444;
}

.appointment-reschdule-btn {
  background-color: #e92125 !important;
  align-self: center;

}

.appointment-reschdule-btn a {
  color: #fff !important;
}

.order-pay-btn {
  background-color: green !important;
}

.order-pay-btn a {
  color: #fff !important;
}

.appointment-reschdule-btn:hover {
  background-color: #d91c20 !important;
}

.patient-appointment-table-heading {
  color: #787878 !important;
  font-family: "Roboto", sans-serif;
}

.popUpModal>div>div {
  background: transparent;
  border: 0px solid rgba(0, 0, 0, 0.2);
  border-bottom: 0px solid transparent !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px;
}

.popUpModal>div>div>div>button {
  position: absolute;
  font-size: 40px;
  right: 5px;
  top: 0px;
}

.telemedicine-list>li {
  font-weight: 400;
  line-height: 28px;
}

.telemedicine-list-heading {
  font-family: "Roboto Condensed", sans-serif;
  line-height: 1em;
  margin-bottom: 20px;
  font-weight: 600;
  color: #444;
}

.covid-info-list>li {
  list-style: circle;
  margin-left: 20px;
}

.covid-info-list-heading {
  color: #787878;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  line-height: 27px;
}

.covidbox {
  width: 100%;
  height: auto;
  overflow: hidden;
  float: left;
  background: #eaf3fc;
  border-radius: 3px;
  padding: 25px;
  min-height: 434px;
}

.covidbox2 {
  width: 100%;
  height: auto;
  overflow: hidden;
  float: left;
  background: #eaf3fc;
  border-radius: 3px;
  padding: 25px;
  min-height: 700px;
}

.banerWithTextHeading {
  font-size: 52px;
  color: #ffff;
  font-weight: 700;
  margin-bottom: 5px;
}

.banerWithTextSubHeading {
  color: #fff;
  font-weight: 700;
  text-decoration: none;
}

.bookCovidTestLabel {
  display: block;
  color: #003242;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
}

.bookCovidTestText {
  border-color: transparent;
  border: 2px solid #e5e5e5;
  padding: 15px 25px;
  background: #fff;
  color: #7a8a9e;
  font-size: 14px;
  margin: 0;
  border-radius: 0px;
  width: 100%;
}

.bookCovidTestBtn {
  border-color: transparent;
  border: 2px solid #e5e5e5;
  padding: 10px 25px;
  background: #fff;
  color: #7a8a9e;
  font-size: 14px;
  margin: 0;
  border-radius: 0px;
  width: 100%;
}

.bookCovidTestBtn:hover {
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
  color: #fff;
  border: 1px solid transparent;
}

.aboutUsCheckIcon {
  border: 2px solid #f0f3f4;
  height: 50px;
  width: 60px;
  text-align: center;
  line-height: 50px;
  color: #1a4774;
  border-radius: 50%;
  margin-right: 20px;
}

.ourMissionContainer {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  box-shadow: 0 0 8px rgb(0 0 0 / 6%);
  padding: 30px 45px;
  min-height: 303px;
}

.ouMissioniconContainer {
  text-align: center;
  margin: 0 auto;
  display: inline-block;
  margin-bottom: 23px;
}

.ourMissionIcon {
  width: 60px;
  height: 50px;
  -o-object-fit: contain;
     object-fit: contain;
  vertical-align: baseline;
}

.missionTitle {
  font-size: 20px;
  font-weight: 700;
  display: block;
  margin-bottom: 10px;
}

.ourmissionBottomImg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.labTeamBtn {
  width: auto;
  float: left;
  padding: 10px 15px;
  margin-right: 8px;
  border-radius: 3px;
  color: #787878 !important;
  background: #f2f2f2;
  font-size: 13px;
  cursor: pointer;
}

.labTeamBtnActive {
  width: auto;
  float: left;
  padding: 10px 15px;
  margin-right: 8px;
  border-radius: 3px;
  font-size: 13px;
  cursor: pointer;
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%) !important;
}

.labTeamBtn:hover {
  color: #fff !important;
  cursor: pointer;
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%) !important;
}

.containerWithShadow {
  background-color: #fff;
  box-shadow: -4px 6px 31px -20px rgba(0, 0, 0, 0.75);
  border-radius: 5px;
}

.administratorDoctorSLotsSection {
  display: flex;
  flex-direction: row;
}

.cartTable tr td {
  border: 1px #eee solid;
  padding: 7px 12px;
}

.react-bootstrap-table-pagination-list>ul {
  display: flex !important;
  justify-content: flex-end !important;
}

/* .smallVideoContainer{
  position: fixed !important;
  bottom: 0% !important;
  right: 0% !important;
  z-index: 9999;
} */
.room {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.callContainer {
  display: flex;
  align-items: center;
}

.notificationContainer {
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 5%);
  -webkit-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 5%);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%) !important;
  color: #fff;
}

.chatbutton {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

.mf-content {
  padding: 12px 17px 13px;
  display: inline-block;
  max-width: 80%;
}

.mf-date {
  display: block;
  color: #b3b3b3;
  margin-top: 7px;
}

.mf-date>i {
  font-size: 14px;
  line-height: 100%;
  position: relative;
  top: 1px;
}

.incoming_msg_img {
  display: inline-block;
  width: 8%;
}

img {
  max-width: 100%;
}

.inbox_msg {
  border: 1px solid #c4c4c4;
  clear: both;
  overflow: hidden;
}

.paymentSuccessBackground {
  background: url(/static/media/paymentSuccess.4a429e99.jpg);
  background-position: right;
  background-size: 80% 100%;
  background-repeat: no-repeat;
}

.paymentFailBackground {
  background: url(/static/media/paymentFail.3835a3f7.jpg);
  background-position: right;
  background-size: 80% 100%;
  background-repeat: no-repeat;
}

.disableBtn {
  pointer-events: none !important;
  cursor: default !important;
}

.chat-frame {
  position: relative;
  height: 100%;
  width: 100%;
  border: transparent;
}

.contact-info-box .title {
  position: relative;
  border-bottom: 2px solid #ececec;
  padding-bottom: 25px;
  font-weight: 600;
  margin-bottom: 40px;
  margin-right: 50px;
}

.contact-info-box .title::after {
  content: "";
  left: 0;
  bottom: -3px;
  width: 80px;
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
  height: 4px;
  position: absolute;
}

.contact-info-box li,
.contact-info-box li a {
  font-size: 18px;
  position: relative;
  display: flex;
  color: #003242;
  font-weight: 500;
}

.contact-info-box li i {
  font-size: 24px;
  left: 0;
  top: 0;
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.contact-info-box li {
  padding-left: 10px;
}

.contact-info-box .icon {
  margin-right: 20px;
  -webkit-transform: translateY(5px);
  transform: translateY(5px);
}

.contact-info-box .content {
  line-height: 28px;
  font-weight: 500;
}

.contact-info-box li a {
  display: flex;
}

.contact-info-box ul li:not(:first-child) {
  margin-top: 30px;
}

.contact-info-box li:first-child .icon {
  margin-right: 26px;
}

.contact-info-box li:first-child {
  margin-bottom: -5px;
}

.selectsect {
  border-color: transparent;
  border: 2px solid #e5e5e5;
  padding: 19px 8px;
  background: #fff;
  color: #7a8a9e;
  font-size: 14px;
  margin: 0;
  border-radius: 0px;
  width: 100%;
}

.contact-form-touch .contact-form-getin input,
.contact-form-touch .contact-form-getin textarea {
  border-color: transparent;
  border: 2px solid #e5e5e5;
  padding: 15px 25px;
  background: #fff;
  color: #7a8a9e;
  font-size: 14px;
  margin: 0;
  border-radius: 0px;
  width: 100%;
}

.contact-form-touch .contact-form-getin .form-group {
  margin-bottom: 25px;
}

.contact-form-touch .title {
  font-weight: 700;
  margin-bottom: 40px;
  color: #1a4774;
}

.contact-form-touch .contact-form-getin textarea {
  min-height: 145px;
}

.contact-form-touch .contact-form-getin label {
  display: block;
  color: #003242;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
}

.contact-form-touch .contact-form-getin .form-group {
  position: relative;
  margin-bottom: 35px;
}

.contact-info-box-wrap .contact-info-box ul {
  padding: 0;
}

.testScroll::-webkit-scrollbar {
  width: 10px;
}

.testScroll::-webkit-scrollbar-thumb {
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%) !important;
  border-radius: 2px !important;
}

/* ::-webkit-scrollbar-thumb {
  background: linear-gradient( 
90deg, #476b8f 0%, #1a4774 100%);
  border-radius: 1px;

  background-color: red !important;
} */

/* Inner Page Select Handler Css */
.dashboardBtnList-item-doc-active {
  border: 1px solid #1a4774 !important;
  border-radius: 0px !important;
  text-decoration: none;
}

.dashboardBtnList-item-doc-active a {
  color: #fff !important;
  text-decoration: none;
}

.default-color-and-hover-doc a {
  color: grey !important;
  text-decoration: none;
}

.default-color-and-hover-doc:hover {
  border-radius: 0px !important;
  border: 1px solid #1a4774 !important;
}

.default-color-and-hover-doc:hover a {
  text-decoration: none;
}

/* Ahsan Work Css */
.about-thumb-wrap {
  position: relative;
  padding-right: 40px;
  /* margin-bottom: 40px; */
}

.about-thumb img {
  margin-bottom: 25px;
}

.about-content p {
  margin-bottom: 10px;
}

.about-thumb-wrap .about-content {
  position: absolute;
  background: #fff;
  padding: 30px 35px;
  box-shadow: 0 16px 32px 0px #048ec538;
  bottom: 70px;
  left: -50px;
}

.content .title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
  line-height: 34px;
}

.about-content::after {
  content: "";
  right: 0;
  bottom: -18px;
  border-top: 18px solid #fff;
  position: absolute;
  border-left: 18px solid transparent;
}

.about-content .content {
  font-size: 16px;
  font-weight: 700;
}

.dots {
  position: absolute;
  top: 20px;
  right: 25px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.dots span {
  height: 10px;
  width: 10px;
  background: #1a4774;
  box-shadow: 0 4px 8px 0px rgba(4, 142, 197, 0.349);
  border-radius: 50%;
}

.dots span:first-child {
  margin-right: 10px;
}

.about-list {
  padding-bottom: 0;
  border: none;
  margin-bottom: 30px;
  margin-top: 15px;
}

.about-content .content .counter {
  font-size: 80px;
  font-weight: 700;
}

.serachsect {
  width: 100%;
  height: 45px;
  overflow: hidden;
  float: left;
  border: 1px #eee solid;
  padding: 0px 12px;
  margin-bottom: 15px;
  font-size: 13px;
}

.curs {
  cursor: pointer;
}

.react-tel-input .form-control {

  width: 100% !important;
}

.fontsizecus {
  font-size: 14px !important;
}

.st-sticky-header {
  position: fixed;
  z-index: 200;
  width: 100%;
  top: 0;
  left: 0;
}

.opt {
  color: #1a4774;
  cursor: pointer !important;
  -webkit-appearance: none;
  appearance: none;
}

.opt:hover {
  background-color: linear-gradient(90deg, #476b8f 0%, #1a4774 100%) !important;
}


.ReactFlagsSelect-module_selectBtn__19wW7 {
  background: #fff !important
}


.dashboardBtnList-item-disbaled {
  border-radius: 3px;
  width: 100%;
  background-color: green !important;
  color: #fff;
  height: 100%
}

.coll{
  color:linear-gradient(90deg, #476b8f 0%, #1a4774 100%) !important;
}
.coll:hover{
  color:linear-gradient(90deg, #476b8f 0%, #1a4774 100%) !important;
}

button[disabled]{
  border: 1px solid #999999 !important;
  background-color: #cccccc !important;
  color: #666666 !important;
}
.ql-indent-1{
  color:#003242 !important;
  text-align: center !important;
  margin-bottom: 20px !important;
}

#someselector > li{
  list-style: unset !important;
}
#set * {
    padding: 0;
    padding: initial;
    box-sizing: inherit !important;
}
#setpripol * {
    padding: inherit;
    box-sizing: inherit !important;
}
#setpripol1 * {
   
    box-sizing: inherit !important;
}

.table-responsive {
 max-height: 500px!important;
 scrollbar-width:10px!important;

}


.table-responsive::-webkit-scrollbar{
    background-color: #dfeffe!important;
    height: 10px!important;
    width:10px!important;
}
thead{
  position: -webkit-sticky!important;
  position: sticky!important;
  top:0!important;
 z-index: 1!important;
background-color: #dfeffe!important;
color:#3b6188!important;
}
table  { border-collapse: collapse; width: 100%; }



.linkhov:hover{
  color:$themepink-color !important;
  text-decoration: none !important;
  transition:ease-in  0.7s !important;
}
.Typograpy_Text_h1{
font-weight: 500 !important;
text-transform: capitalize;
}
.Typograpy_Text_h4{
font-size: 18px;
font-weight: 500 !important;
text-transform: capitalize;
}


@media (max-width: 575.98px) 

{
.Typograpy_Text_h1{
  font-size: 40px !important;
}
.Typograpy_Text_h4{
  font-size: 12px !important;
}

}
@media (max-width: 400px) 
{

.Typograpy_Text_h1{
  font-size: 35px !important;
}
.Typograpy_Text_h4{
  font-size: 12px !important;
}

}

/* #linone {
  list-style: none !important;
}
#linone > li {
  list-style: none !important;
} */
ul {
  list-style: none !important;
}
li {
  list-style: none !important;
}

ul {
  list-style: none !important;
}

a:link {
  text-decoration: none;
}

.footer-nav-links {
  color: #787878;
  font-weight: bold;
}

.footer-nav-links:hover {
  color: #1a4774;
}

.footer-top {
  background-color: #f2f2f2 !important;
}

h3 {
  font-family: "Roboto Condensed", sans-serif;
  line-height: 1em;
  margin-bottom: 20px;
  font-weight: bold;
}

h3+.h-decor {
  position: relative;
  top: -20px;
  height: 2px;
  width: 27px;
}

.h-decor {
  display: inline-block;
  height: 3px;
  width: 53px;
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
}

.footer-bottom {
  padding: 11px 0;
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
  font-size: 15px;
  line-height: 26px;
  font-weight: 300;
}

.fa-chevron-right {
  /* font-size: 12px !important;
  margin-right: 5px; */
  color: #1a4774;
  padding-right: 12px;
  font-size: 10px;
  position: relative;
  top: -2px;
}

.qr-code h3 {
  font-size: 14px;
  margin-top: 30px;
  margin-bottom: 0;
  padding-bottom: 20px;
}

.phone {
  font-weight: 600;
  color: #1a4774;
}


.st_footer_widget_title {
  font-size: 20px;
  position: relative;
  padding-bottom: 17px;
  margin: 0;
  margin-bottom: 30px;
  margin-top: -4px;
  color: #001b4b !important;
  font-weight: 600;
}

.st_footer_widget_title:before {
  content: '';
  position: absolute;
  height: 1px;
  width: 100%;
  max-width: 170px;
  background-color: #eaeaea !important;
  bottom: 0;
  left: 0;
}

.st_footer_widget_nav {
  margin-top: -5px;
}

.st_footer_widget_nav li {
  position: relative;
  margin-bottom: 15px;
}

.st_footer_widget_nav li:last-child {
  margin-bottom: 0;
}

.st_footer_widget_nav a {
  display: inline-block;
  padding-left: 15px;
  position: relative;
  color: #1a4774 !important;
  font-weight: 500;
}

.st_footer_widget_nav a:hover {
  color: #001b4b !important;
  cursor: pointer;
  text-decoration: none;
  margin-left: 5px;
  transition: 0.3s;
}

.st_footer_widget_nav i {
  position: absolute;
  left: 0;
  top: 6px;
  font-size: 12px;
}

.st_mp0 {
  list-style: none;
  margin: 0;
  padding: 0;
}
.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.header {
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
}
.header-info {
  color: #fff;
  margin-left: 20px;
  font-size: 14px;
  font-weight: 400;
}
.header-info:hover{
  color: #fff;
  margin-left: 20px;
  font-size: 14px;
  font-weight: 400;
}
.header-search {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-left: 20px;
}

.header-search [class*="icon-"] {
  position: absolute;
  top: 1px;
  bottom: 1px;
  right: 1px;
  z-index: 1;
  cursor: pointer;
  display: block;
  color: #444;
  font-size: 20px;
  line-height: 35px;
  min-width: 40px;
  text-align: center;
  background-color: transparent;
}

.header-search input[type="text"],
.header-search input[type="search"] {
  position: absolute;
  right: 40;
  top: 1px;
  border: 2px solid #fff;
  /* width: 40px; */
  width: 220px;
  height: 38px;
  padding: 15px;
  font-size: 14px;
  line-height: 1.2em;
  color: #fff;
  transition: 0.2s;
  background: transparent;
  border-radius: 50px;
}

.header-search:hover input[type="text"],
.header-search:hover input[type="search"],
.header-search input[type="text"]:focus,
.header-search input[type="search"]:focus {
  width: 220px;
  padding: 0 45px 0 10px;
  border-color: #ccc;
}

.header-search input[type="submit"],
.header-search button[type="submit"] {
  display: none;
}

.header-search ::-webkit-input-placeholder {
  color: #fff;
}

.header-search ::-moz-placeholder {
  color: #fff;
}

.header-search :-ms-input-placeholder {
  color: #fff;
}

.header-search :-moz-placeholder {
  color: #fff;
}
.country-drop {
  margin-left: 190px;
}
.country-drop .form-control {
  border-radius: 0;
  padding: 10px 7px 10px 7px;
}
.dropbtn {
  background-color: transparent;
  color: #fff;
  padding: 16px;
  /* margin-left: 170px; */
  font-size: 14px;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 9999;
}

.dropdown-content span {
  color: #001b4b;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content span:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: transparent;
}

.dropbtn img {
  width: 30px;
  height: 20px;
  -o-object-fit: cover;
     object-fit: cover;
  margin-right: 10px;
  position: relative;
  top: -1px;
}

.dropdown-content span img {
  width: 30px;
  height: 20px;
  -o-object-fit: cover;
     object-fit: cover;
  margin-right: 10px;
}
.header-icon-item {
  background-color: #fff;
  border-radius: 50%;
  height: 22px;
  width: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.header-icon-item i {
  text-align: center;
  margin-right: 0px !important;
  color: linear-gradient(90deg, #476b8f 100%, #1a4774 0%);
  font-size: 10px;
}
.header-icon-item:hover{
  opacity: 0.5;
}
.search-icon{
  position: absolute;
  right: 0px;
  left: 191px;
  top: 12px;
  color: #fff;
}




.searchInputs {
  z-index: 1;
}

input:focus {
  outline: none;
}

.dataResult {
  margin-top: 5px;
  cursor: pointer;
  width: 200px;
  height: 240px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  overflow-y: auto;
  z-index: 9999;
  position: absolute !important;
  top: 35px;
  right: -150px;
  border-radius: 10px;
}

.dataResult .dataItem {
  width: 100%;
  height: 50px;
  color: black;
}

.dataItem p {
  margin-left: 10px;
}
a {
  text-decoration: none;
}

.dataItem:hover {
  color: #0066b3;
}

#clearBtn {
  cursor: pointer;
}


.nav-link {
  font-size: 13px !important;
  font-weight: 600 !important;
  padding: 0.05rem !important;
  margin-top: 10px;
  color: #1a4774 !important;
}
.nav-link:hover {
  color: #e92125 !important;
}
.dropdown-menu {
  border: none;
  min-width: 250px;
  max-width: 300px;
}
.dropdown-item {
  color: #1a4774;
  font-size: 13px;
  /* text-transform: capitalize; */
  padding: 12px 3px;
  padding-left: 18px !important;
  padding-right: 18px !important;
  font-family: "Roboto", sans-serif;
  display: block !important;
  font-weight: 500;
  text-overflow: clip;
}

.sticky-top {
  top: -10px;
}
.profile-nav-dropdown {
  min-width: 130px !important;
  max-width: 200px !important;
  left: -20px;

}

@media (min-width: 1200px) {
  .dropdown:hover > .dropdown-menu {
    display: block;
  }
  
  .dropdown-item:hover {
    background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
    color: #fff;
  }
}

.dropdown-item-user {
  color: #001b4b;
  font-size: 14px;
  text-align: center;
  text-transform: capitalize;
  padding: 5px 10px;
  font-family: "Roboto", sans-serif;
  display: inline-block !important;
  font-weight: 500;
  text-overflow: clip;
  text-decoration: none;
  width: 100%;
}
.input-login-modal {
  width: 100%;
  height: 42px;
  overflow: hidden;
  float: left;
  border: 1px lightgray solid;
  border-radius: 2px !important;
  padding-left: 12px;
  margin-bottom: 20px;
  color: #a9a9a9;
}
.label-name-login {
  color: #444;
  font-weight: 400;
  font-size: 15px;
}
.modal-title {
  font-family: "Roboto Condensed", sans-serif;
  line-height: 1.55em;
  color: #444;
  font-weight: 600;
  font-size: 20px;
}
.signIn-link{
  color: #1a4774;
}

.input-login-modal {
    width: 100%;
    height: 42px;
    overflow: hidden;
    float: left;
    border: 1px lightgray solid;
    border-radius: 2px !important;
    padding-left: 12px;
    margin-bottom: 20px;
    color: black;
  }
  .label-name-login {
    color: #444;
    font-weight: 400;
    font-size: 15px;
  }
  .modal-title {
    font-family: "Roboto Condensed", sans-serif;
    line-height: 1.55em;
    color: #444;
    font-weight: 600;
    font-size: 20px;
  }
  .signIn-link{
    color: #1a4774;
  }
  .text-error{
    margin-top: 0px;
    padding: 0px;
    height: 10px;
    position: absolute;
    top: 65px;
    font-size: 11px;
    color: red;
    text-transform: capitalize;
  }
  

.contact-info-box .title {
    position: relative;
    border-bottom: 2px solid #ececec;
    padding-bottom: 25px;
    font-weight: 600;
    margin-bottom: 40px;
    margin-right: 50px;
  }
  
  .contact-info-box .title::after {
    content: "";
    left: 0;
    bottom: -3px;
    width: 80px;
    background: linear-gradient( 90deg, #476b8f 0%, #1a4774 100%);
    height: 4px;
    position: absolute;
  }
  
  .contact-info-box li, .contact-info-box li a {
    font-size: 18px;
    position: relative;
    display: flex;
    color: #003242;
    font-weight: 500;
  }
  
  .contact-info-box li i {
    font-size: 24px;
    left: 0;
    top: 0;
    background: linear-gradient( 90deg, #476b8f 0%, #1a4774 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
  }
  
  .contact-info-box li {
    padding-left: 10px;
  }
  
  .contact-info-box .icon {
    margin-right: 20px;
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }
  
  .contact-info-box .content {
    line-height: 28px;
    font-weight: 500;
  }
  
  .contact-info-box li a {
    display: flex;
  }
  
  .contact-info-box ul li:not(:first-child) {
    margin-top: 30px;
  }
  
  .contact-info-box li:first-child .icon {
    margin-right: 26px;
  }
  
  .contact-info-box li:first-child {
    margin-bottom: -5px;
  }
  
  .selectsect{
    border-color: transparent;
    border: 2px solid #e5e5e5;
    padding:19px 8px;
    background: #fff;
    color: #7a8a9e;
    font-size: 14px;
    margin: 0;
    border-radius: 0px;
    width: 100%;
  }
  
  .contact-form-touch .contact-form-getin input, .contact-form-touch .contact-form-getin textarea {
    border-color: transparent;
    border: 2px solid #e5e5e5;
    padding: 15px 25px;
    background: #fff;
    color: #7a8a9e;
    font-size: 14px;
    margin: 0;
    border-radius: 0px;
    width: 100%;
  }
  
  .contact-form-touch .contact-form-getin .form-group {
    margin-bottom: 25px;
  }
  
  .contact-form-touch .title {
    font-weight: 700;
    margin-bottom: 40px;
    color: #1a4774;
  }
  
  .contact-form-touch .contact-form-getin textarea {
    min-height: 145px;
  }
  
  .contact-form-touch .contact-form-getin label {
    display: block;
    color: #003242;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
  }
  
  .contact-form-touch .contact-form-getin .form-group {
    position: relative;
    margin-bottom: 35px;
  }
  
  .contact-info-box-wrap .contact-info-box ul {
    padding: 0;
  }
.icn-text-alt {
  /* display: flex; */
  text-decoration: none;
  max-width: 100%;
  margin-bottom: 6px;
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
  padding: 20px;
  color: #fff;
}
.heading {
  margin-bottom: 0;
  font-size: 20px;
  line-height: 34px;
  color: #fff;
  transition: 0.25s;
}
.card-discription {
  font-size: 16px;
  line-height: 25px;
  font-family: 'Work Sans', sans-serif;
}
.card-size-home{
  height: 320px;
}
/* .size-slider {
  width: 100% !important;
  height: 450px !important;
}
.carousel-caption {
  background: linear-gradient( to bottom, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100% );
  border-radius: 5px;
  left: 5% !important;
  width: 55% !important;
}
.heading-Slider {
  font-size: 42px;
  font-family: "Roboto Condensed", sans-serif;
  text-transform: uppercase ;
}
.discription-Slider {
  font-size: 16px;
  font-family: "Roboto Condensed", sans-serif;
  letter-spacing: 0.03em;
} */
.caption {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0.3) 100%
  );
  padding: 10px;
  border-radius: 5px !important;
  color: white;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}

.caption h2 {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}

.caption p {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-delay: 2.2s;
          animation-delay: 2.2s;
}

.caption a {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-delay: 2.4s;
          animation-delay: 2.4s;
}

.cen {
  display: flex;
  flex-direction: column !important;
}

.slider-wrapper {
  position: relative;
}
.slider-wrapper .slider-overlay {
  position: absolute;
  left: 5%;
  top: 50%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 55%;
  display: flex;
  flex-direction: inherit;
  justify-content: center;
  /* background: rgba(134, 134, 134, 0.2); */
}

.zoom-in-zoom-out {
  -webkit-animation: opacity 500ms ease-in-out 0s, visibility 500ms ease-in-out 0s;
          animation: opacity 500ms ease-in-out 0s, visibility 500ms ease-in-out 0s;
  
}







.fadeInDown {
  -webkit-animation-name: fadeInDown;
          animation-name: fadeInDown;
  -webkit-animation-duration: 0.9s;
  -webkit-animation-delay:50s;
          animation-delay:50s;
  animation-duration: 0.9s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

  
  
@-webkit-keyframes fadeInDown {
  0% {
      opacity: 0;
      -webkit-transform: translateY(-20px);
  }
  100% {
      opacity: 1;
      -webkit-transform: translateY(0);
  }
    }

  
  
@keyframes fadeInDown {
  0% {
      opacity: 0;
      -webkit-transform: translateY(-20px);
  }
  100% {
      opacity: 1;
      -webkit-transform: translateY(0);
  }
    }
  
  

    @-webkit-keyframes fadeInUp {
      from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 60%, 0);
      }
    
      to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
      }
    }
  
  

    @keyframes fadeInUp {
      from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 60%, 0);
      }
    
      to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
      }
    }
    
    .fadeInUp {
      -webkit-animation-name: fadeInUp;
              animation-name: fadeInUp;
      -webkit-animation-duration: 0.9s;
      -webkit-animation-delay:50s;
              animation-delay:50s;
      animation-duration: 0.9s;
      -webkit-animation-timing-function: linear;
              animation-timing-function: linear;
    }










.wrap {
  height: 200px;
  width: 300px;
  overflow-y: hidden;
  position: relative;
}

.typography_Heading {
  font-family: "Montserrat", sans-serif;
  font-size: calc(32px + .5vw) !important;
  font-weight: 600 !important;
  text-transform: uppercase;
}
.img-inline-res {
  width: calc(100% - 70%);
  margin: 0px 15px 0px 0px !important;
}

.btn-custom-animation:hover {
  border: 1px solid #0066b3 !important;
  color: #000 !important;
  background-color: #fff !important;
}

.typograpy_title {
  font-size: 16px;
  color: #fff;
  font-weight: 400 !important;
}
.typograpy_title0 {
  font-size: 2vw !important;
  color: #fff;
  font-family: "Dancing Script", cursive !important;
}

.ball {
  animation: opacity 0.5s, bounce 2.5s infinite alternate;
  -webkit-animation: opacity 0.5s, bounce 2.5s infinite alternate;
}
@keyframes bounce {
  from {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
  to {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
@-webkit-keyframes bounce {
  from {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
  to {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}



.btn_text_typography {
  font-weight: 600 !important;
  text-transform: uppercase;
  font-size: 12px !important;
  padding: 12px 30px !important;
}

/* Slick Dots Ka Color Ki Css */
.slick-dots {
  position: absolute;
  list-style: none;
  display: block;
  text-align: center;
  margin-bottom: 60px;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 10px;
  margin-left: 15px;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  display: block;
  height: 10px;
  width: 10px;
  /* outline: #e1e5e9 !important; */
  color: #ffffff !important;
  border: 2px solid #ffffff !important;
  border-radius: 50% !important;
  line-height: 0;
  font-size: 0;
  padding: 5px;
  cursor: pointer;
}
  
  .slick-dots li button:before {
    position: absolute;
    content: "•";
    font-family: "slick";
    font-size: 10px;
    padding-top: 7px;
    padding-right: 6px;
    line-height: 2px;
    text-align: center;
    color: transparent !important;
    border-radius: 10px;
  }
  .slick-dots li.slick-active button:before {
    color:#ffffff !important;
    opacity: 1 !important;
  }
.slick-initialized .slick-slide.slick-active {
  z-index: 1 !important;
}




.pakage-female-box {
  width: 100%;
  min-height: 123px;
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
}


.pkg-types {
    background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
    padding: 15px 20px 25px;
  }
.sidebar {
    background: linear-gradient( 90deg, #476b8f 0%, #1a4774 100%);
    padding: 15px 20px 25px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .sidebar ul {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: auto;
    margin-bottom: 10px;
    overflow: hidden;
  }
  
  .sidebar ul li {
    color: #ddd;
    display: block;
    position: relative;
    float: left;
    width: 50%;
    height: auto;
    /* border-bottom: 1px solid #f1f1f1; */
  }
  
  .sidebar ul li:last-child {
    border-bottom: none;
  }
  
  .sidebar ul li input[type=radio] {
    position: absolute;
    visibility: hidden;
  }
  
  .sidebar ul li label {
    display: block;
    position: relative;
    font-weight: 500;
    font-size: 14px;
    padding: 0px 0px 0px 35px;
    height: 40px;
    z-index: 9;
    cursor: pointer;
    -webkit-transition: all 0.25s linear;
    margin: 0 auto 0 auto;
  }
  
  .sidebar ul li:hover label {
    color: #fff;
  }
  
  .sidebar ul li .check {
    display: block;
    position: absolute;
    border: 3px solid #ddd;
    border-radius: 100%;
    height: 25px;
    width: 25px;
    top: 0;
    left: 0;
    z-index: 5;
    transition: border .25s linear;
    -webkit-transition: border .25s linear;
  }
  
  .sidebar ul li:hover .check {
    border: 3px solid #fff;
  }
  
  .sidebar ul li .check::before {
    display: block;
    position: absolute;
    content: '';
    border-radius: 100%;
    height: 12px;
    width: 12px;
    top: 3px;
    left: 3px;
    margin: auto;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear;
  }
  
  .sidebar input[type=radio]:checked~.check {
    border: 3px solid #fff;
  }
  
  .sidebar input[type=radio]:checked~.check::before {
    background: #fff;
  }
  
  .sidebar input[type=radio]:checked~label {
    color: #fff;
  }
  .sidebar ul h5 {
    margin: 0;
    font-size: 16px;
    color: #fff;
    margin-bottom: 20px;
}
/* .client-card {
  border: 1px solid lightgray;
} */
.clientSliderParent > .slick-slider  > .slick-list > .slick-track > .slick-slide > div {
  margin: 0px 20px !important; 
  border: 1px solid lightgray;
  box-shadow: 0 0 15px rgb(0 0 0 / 10%);
}
.main-testemonail{
flex-direction: column;
width: 100% !important;
height: 100% !important;

}
.testemonial-img{
    border-radius: 50%;
    width: 100px;
    height: 100px;
}
.testemonial-review{
    text-align: center;
    font-size: 15px;
    line-height: 29px;
    max-width: 400px;
    margin-bottom: 2em;
    color: #787878;
}
.consultant-card-main{
    display: flex;
    flex-direction: row;
}

.consultant-cirlce{
    background-color: red;
    width: 83px;
    height: 83px;
    border-radius: 50%;
    font-size: 30px;
    font-weight: bold;
    color: #fff;
}
.consultant-card-main:hover .consultant-cirlce{
    background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
}
.consultant-text-side{
    margin-left: 20px;
    width: 80%;
}
.consultant-link{
    position: absolute;
    right: 58px;
    top: 80px;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Roboto Condensed', sans-serif;
    color: #e92125;
    cursor: pointer;
}
.right-main {
  position: fixed;
  right: 0;
  top: 30%;
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
  width: 95px;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  cursor: pointer;
}
.right-item {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.right-icon {
  font-size: 24px;
  line-height: 1.3em;
  color: #ef6366;
}
.right-para {
  color: #fff;
  text-align: center;
  font-size: 10px;
  line-height: 13px;
  font-family: "Roboto Condensed", sans-serif;
  text-transform: uppercase;
  padding-left: 2px;
}
.emergency-data-refrence {
  position: relative;
}
.detail-right-div-emergency {
  width: 400px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: white;
  position: absolute;
  top: 30%;
  right: 100%;
  display: none;
}
.right-hover-active:hover .detail-right-div-emergency {
  display: block;
  box-shadow: -2px 20px 5px -9px rgba(0, 0, 0, 0.28);
}
.btn-position-emergency-call {
  display: flex;
  justify-content: flex-end;
}
.google-map {
  width: 500px;
  max-width: 100vw;
  height: 312px;
}
.detail-right-div-map {
  width: 500px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: white;
  position: absolute;
  top: 0%;
  right: 100%;
  display: none;
}
.right-hover-active:hover .detail-right-div-map {
  display: block;
  box-shadow: -2px 20px 5px -9px rgba(0, 0, 0, 0.28);
}
.right-main {
  position: fixed;
  right: 0;
  top: 30%;
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
  width: 95px;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  cursor: pointer;
}
.right-item {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.right-icon {
  font-size: 24px;
  line-height: 1.3em;
  color: #ef6366;
}
.right-para {
  color: #fff;
  text-align: center;
  font-size: 10px;
  line-height: 13px;
  font-family: "Roboto Condensed", sans-serif;
  text-transform: uppercase;
  padding-left: 2px;
}
.emergency-data-refrence {
  position: relative;
}
.detail-right-div-emergency {
  width: 400px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: white;
  position: absolute;
  top: 30%;
  right: 100%;
  display: none;
}
.right-hover-active:hover .detail-right-div-emergency {
  display: block;
  box-shadow: -2px 20px 5px -9px rgba(0, 0, 0, 0.28);
}
.btn-position-emergency-call {
  display: flex;
  justify-content: flex-end;
}

.google-map {
  width: 500px;
  max-width: 100vw;
  height: 312px;
}

.avatar-whatsapp {
  width: 52px;
  height: 52px;
  box-sizing: border-box;
  border: 3px solid #fff;
  border-radius: 50%;
  overflow: hidden;
  -webkit-transform: translatey(0px);
          transform: translatey(0px);
  -webkit-animation: float 6s ease-in-out infinite;
          animation: float 6s ease-in-out infinite;
  position: fixed;
  bottom: 55px;
  right: 12px;
  z-index: 999;
  background: #25d366;
}

.avatar-whatsapp i {
  color: #fff;
  font-size: 22px;
  font-weight: lighter;
  text-align: center;
  display: block;
  line-height: 46px;
  font-weight: 600;
}

@-webkit-keyframes float {
  0% {
    box-shadow: 0px 5px 10px 0px rgb(106 106 106 / 15%);
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
  50% {
    box-shadow: 0px 5px 10px 0px rgb(106 106 106 / 15%);
    -webkit-transform: translatey(-20px);
            transform: translatey(-20px);
  }
  100% {
    box-shadow: 0px 5px 10px 0px rgb(106 106 106 / 15%);
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
}

@keyframes float {
  0% {
    box-shadow: 0px 5px 10px 0px rgb(106 106 106 / 15%);
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
  50% {
    box-shadow: 0px 5px 10px 0px rgb(106 106 106 / 15%);
    -webkit-transform: translatey(-20px);
            transform: translatey(-20px);
  }
  100% {
    box-shadow: 0px 5px 10px 0px rgb(106 106 106 / 15%);
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
}

.backToTop {
  position: fixed;
  z-index: 1020;
  right: 15px;
  bottom: -25px;
  opacity: 0;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
  font-size: 18px;
  line-height: 52px;
  color: #fff;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 3px 3px rgb(0 0 0 / 10%);
  transition: all 0.25s, bottom 0.75s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
.backToTop [class*="fa"] {
  display: inline-block;
  transition: 0.5s;
}
.backToTop.visible {
  opacity: 1;
  bottom: 5px;
}
.backToTop:hover {
  box-shadow: 0 0 0 10px rgb(92 104 149 / 20%);
}
.backToTop:hover [class*="fa"] {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  -webkit-filter: FlipH;
          filter: FlipH;
}

.left-main {
  position: fixed;
  left: 10px;
  top: 30%;
  background: transparent;
  width: 95px;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  cursor: pointer;
}
.left-main:hover {
  cursor: pointer;
}
.left-covid-info {
  box-sizing: border-box;
  border: 0;
  margin-top: 10px;
  overflow: hidden;
  z-index: 99;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  border-radius: 5px;
  width: 70px;
  height: 80px;
  background-color: #e92125;
}
.left-covid-people {
  box-sizing: border-box;
  border: 0;
  margin-top: 10px;

  overflow: hidden;
  z-index: 99;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  border-radius: 5px;
  width: 70px;
  line-height: normal;
  height: 87px;
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
}
.left-hotcall {
  box-sizing: border-box;
  border: 0;
  margin-top: 10px;

  overflow: hidden;
  line-height: 26px;
  z-index: 999;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  border-radius: 5px;
  width: 70px;
  height: 85px;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 23%);
  -webkit-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 23%);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.23);
  background-color: #fff;
  transition: 0.5s;
}
.left-hotcall:hover {
  transition: 0.5s;
  height: 110px;
  bottom: 190px;
}
.left-img-size {
  padding: 7px 10px 0px 10px;
  width: 100%;
}
.left-text {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 900;
}

.covidTeamCardImg {
  border-radius: 50%;
  width: 170px;
  height: 170px;
  text-align: center;
  position: relative;
}
.covidTeamCardText {
  margin-bottom: 0;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.socialmediasIconCovidTeam {
  display: flex;
  flex-direction: row;
}
.CovidTeamCardBtn {
  width: 100px;
  display: block;
  height: auto;
  overflow: hidden;
  float: left;
  text-align: center;
  color: #fff;
  background: #1a4774;
  border-radius: 50px;
  padding: 4px 3px;
  font-size: 13px;
  line-height: 27px;
}
.CovidTeamCardBtn:hover {
  background: #133b62;
  color: #fff !important;
}
.covidTeamImgIcon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 41%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.covidTEamHeadingCard{
  padding-top: 20px;
}

.covidTeamCardImg {
    border-radius: 50%;
    width: 170px;
    height: 170px;
    text-align: center;
    position: relative;
  }
  .covidTeamCardText {
    margin-bottom: 0;
    display: inline-block;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .socialmediasIconCovidTeam {
    display: flex;
    flex-direction: row;
  }
  .CovidTeamCardBtn {
    width: 100px;
    display: block;
    height: auto;
    overflow: hidden;
    float: left;
    text-align: center;
    color: #fff;
    background: #1a4774;
    border-radius: 50px;
    padding: 4px 3px;
    font-size: 13px;
    line-height: 27px;
  }
  .CovidTeamCardBtn:hover {
    background: #133b62;
    color: #fff !important;
  }
  .covidTeamImgIcon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 41%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .covidTEamHeadingCard{
    padding-top: 20px;
  }
  
@font-face {
  font-family: 'SutonnyMJ';
  src: url(/static/media/SutonnyMJ-Regular.e73cd0d9.ttf);
  
}
.newsectionm {
  width: 100%;
  height: auto;
  overflow: hidden;
  float: left;
  border: 1px #eee solid;
  background: #fff;
  padding: 12px;
  border-radius: 2px;
  margin-bottom: 30px;
}
.news_left {
  width: 40%;
  height: auto;
  overflow: hidden;
  float: left;
  padding-right: 10px;
}
.news_right {
  width: 60%;
  height: auto;
  overflow: hidden;
  float: left;
  padding-left: 10px;
}
.newsimagescttn {
  width: 100%;
  border: 1px #ccc solid;
  float: left;
  height: 151px;
  overflow: hidden;
}

@media (max-width: 768px) {
  .news_left {
    width: 65%;
    height: auto;
    overflow: hidden;
    margin: auto;
    display: block;
    text-align: center;
  }
  .news_right {
    width: 100%;
    height: auto;
    overflow: hidden;
    float: left;
    padding-top: 12px;
  }
}

.banglafont {
  font-family: "SutonnyMJ" !important;
  font-size: 18px !important;
}
.descriptn_news {
  width: 100%;
  height: 75px;
  overflow: hidden;
  float: left;
  font-size: 15px !important;
  text-align: justify;
  line-height: 19px;
}
.news-meta ul {
  display: flex;
  font-size: 14px;
  padding: 0;
}
.news-meta ul li {
  list-style: none;
}
.news-meta ul a {
  color: #003242;
  font-weight: 700;
}
.news-meta ul a i {
  background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.news-meta ul li:last-child {
    margin-left: 20px;
  }
  
.banglafont {
    font-family: 'SutonnyMJ'!important;
    font-size: 18px!important;
    color: #0e5684!important;  
}
.newsbtn  {
  color: #0e5684!important;
  clear: both !important;
  width: 100% !important;
  float: left !important;
}
.newsbtn:hover {
  color: #ed1c24!important;
}
.thrdoptions {
  width: 100%;
  height: auto;
  overflow: hidden;
  float: left;
}
.facebookpostd24 {
  width: 100%;
  height: auto;
  overflow: hidden;
  float: left;
  padding: 0px 12px;
  position: relative;
  z-index: 900;
  margin-top: -30px;
}
.facebookpostd24_info {
  width: 100%;
  height: auto;
  overflow: hidden;
  float: left;
  background: #f9f9f9;
  border-radius: 2px;
  padding: 15px;
}
.newsbg {
  width: 100%;
  height: auto;
  overflow: hidden;
  float: left;
  background-size: cover !important;
  min-height: 450px;
  position: relative;
}
.maintitle_social {
  color: #fff;
  position: absolute;
  top: inherit;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 65px 17px 15px;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1)
  );
}

.post_fetureimage {
  width: 100%;
  height: 250px;
  overflow: hidden;
  float: left;
  border-radius: 1px;
  position: relative;
}

.list_fbpost {
  width: 100%;
  height: auto;
  overflow: hidden;
  float: left;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 25px;
  padding: 8px;
  background: #fdfdfd;
}

.post_fetureimage img {
  width: 100%;
}
.facebookpostd {
  width: 100%;
  height: auto;
  overflow: hidden;
  float: left;
  background: #fff;
  padding: 12px;
  position: relative;
  border: 1px;
}
.postdates {
  position: absolute;
  top: 11px;
  right: 7px;
  letter-spacing: -0.9px;
  background-color: rgba(14, 86, 132, 0.9);
  width: 68px;
  text-align: center;
  padding: 12px 3px;
  color: #fff;
  line-height: 18px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.descriptn_news {
  width: 100%;
  height: 75px;
  overflow: hidden;
  float: left;
  font-size: 15px !important;
  text-align: justify;
  line-height: 19px;
}

* {
  box-sizing: border-box;
}
html {
  height: 100%;
}
body {
  height: 100%;
  margin: 0px;
}
.App {
  height: 100%;
}
.wrapper {
  height: 100%;
  display: grid;
  padding: 20px;
}
.inner {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  background-color: #343a40 !important;
  /* padding: 10px; */
}
video {
  width: 100%;
}
.controlsHoverActive:hover > .controlsHover {
  display: block !important;
}
@media only screen and (max-width: 768px) {
  .wrapper {
    display: grid;
    grid-template-columns: 1fr !important;
  }
}

.notFoundContainer {
  background: #476b8f;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  max-height: 800px;
  overflow: hidden;
}
.c {
  text-align: center;
  display: block;
  position: relative;
  width: 80%;
  margin: 100px auto;
}
._404 {
  font-size: 220px;
  position: relative;
  display: inline-block;
  z-index: 2;
  height: 250px;
  letter-spacing: 15px;
}
._1 {
  text-align: center;
  display: block;
  position: relative;
  letter-spacing: 12px;
  font-size: 4em;
  line-height: 80%;
}
._2 {
  text-align: center;
  display: block;
  position: relative;
  font-size: 20px;
}
.text {
  font-size: 70px;
  text-align: center;
  position: relative;
  display: inline-block;
  margin: 19px 0px 0px 0px;
  /* top: 256.301px; */
  z-index: 3;
  width: 100%;
  line-height: 1.2em;
  display: inline-block;
}


.right {
  float: right;
  width: 60%;
}

hr {
  padding: 0;
  border: none;
  border-top: 5px solid #fff;
  color: #fff;
  text-align: center;
  margin: 0px auto;
  width: 420px;
  height: 10px;
  z-index: -10;
}

/* hr:after {
                content: "\2022";
                display: inline-block;
                position: relative;
                top: -0.75em;
                font-size: 2em;
                padding: 0 0.2em;
                background: #33cc99;
            } */


  /* .heading {
    font-size: 17px;
    color: #fff;
    font-weight: 600;
    transition: 0.25s;
  
  } */
  /* .card-discription {
    font-size: 11px;
    line-height: 20px !important;
    font-family: "Work Sans", sans-serif;
  } */
  .card-size-home {
    height: 360px !important;
  }
  
  .designation {
    font-size: 11px;
    float: left;
    text-align: center;
  }
  .magnific-imgs h3 {
    text-align: center;
    font-size: 12px!important;
  }
  .magnific-imgs img {
    height:200px !important;
  }
