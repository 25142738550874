.consultant-card-main{
    display: flex;
    flex-direction: row;
}

.consultant-cirlce{
    background-color: red;
    width: 83px;
    height: 83px;
    border-radius: 50%;
    font-size: 30px;
    font-weight: bold;
    color: #fff;
}
.consultant-card-main:hover .consultant-cirlce{
    background: linear-gradient(90deg, #476b8f 0%, #1a4774 100%);
}
.consultant-text-side{
    margin-left: 20px;
    width: 80%;
}
.consultant-link{
    position: absolute;
    right: 58px;
    top: 80px;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Roboto Condensed', sans-serif;
    color: #e92125;
    cursor: pointer;
}